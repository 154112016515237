import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth_service.service';
import { TokenStorageService } from 'src/app/services/token_storage.service';
import { ServiceProviderService } from 'src/app/services/service-provider.service';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    loginForm = new FormGroup({
        emailAddress: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', Validators.required),
    });

    loading = false;
    isLoginFailed = false;
    errorMessage = '';
    return = '';

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private tokenStorageService: TokenStorageService,
        private loginService: AuthService,
        private route: ActivatedRoute,
        private serviceProviderService: ServiceProviderService
    ) { }

    ngOnInit(): void {
        this.tokenStorageService.clearToken();
        this.route.queryParams.subscribe(params => this.return = params.return || '/settings');
    }

    get formControls(): { [p: string]: AbstractControl } {
        return this.loginForm.controls;
    }

    onSubmit(): void {
        this.loading = true;

        const username = this.formControls.emailAddress.value;
        const password = this.formControls.password.value;
        const basic = btoa(`${username}:${password}`);

        this.loginService.login(basic).subscribe(
            user => {
                this.isLoginFailed = false;
                this.tokenStorageService.saveToken(user.token);
                this.tokenStorageService.saveUser(user);

                this.serviceProviderService.getPayoutsEnabled().subscribe((enabled: boolean) => {
                    this.routeToNextPage(user, enabled);
                });
            },
            (error) => {
                this.loading = false;
                this.isLoginFailed = true;
                this.errorMessage = error;
            }
        );
    }

    private routeToNextPage(user: any, paymentsEnabled: boolean): void {
        if (user.stripeExpressAccountComplete != true && user.stripeExpressSetupUrl != null && !paymentsEnabled) {
            this.loading = false;
            this.router.navigate(["/stripe-setup"])
        } else if (user.stripeExpressAccountComplete != true && user.stripeExpressSetupUrl == null) {
            this.serviceProviderService.post(['create-connected-account'], {}).subscribe(response => {
                this.loading = false;
                this.router.navigate(["/stripe-setup"])
            });
        } else if (user.stripeExpressAccountComplete != true && paymentsEnabled) {
            this.loading = false;
            this.router.navigate(["/stripe-setup-finalize"])
        } else {
            this.loading = false;
            this.router.navigate([this.return]);
        }
    }
}
