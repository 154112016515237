<div class="flex-row padding-top border pb-3 mt-5 center-form">
    <div class="flex-1"></div>
    <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()" class="form-content">
        <h1 class="center-text">Provider Portal</h1>
        <h2 class="center-text">Change Password</h2>

        <mat-form-field>
            <mat-label>Password</mat-label>
            <input type="password" matInput formControlName="password">
            <mat-error *ngIf="formControls.password.hasError('required')">Password is required.</mat-error>
            <mat-error *ngIf="formControls.password.hasError('pattern')">Password doesn't meet requirements</mat-error>
        </mat-form-field>


        <mat-form-field>
            <mat-label>Confirm Password</mat-label>
            <input type="password" matInput formControlName="confirmPassword">
            <mat-error *ngIf="formControls.confirmPassword.hasError('required')">Confirm Password is required.</mat-error>
            <mat-error *ngIf="formControls.confirmPassword.hasError('notSame')">Passwords must match.</mat-error>
        </mat-form-field>

        <div *ngIf="error.length > 0" class="error-text error-padding error-width">
            {{error}}
        </div>

        <div class="flex-row">
            <mat-progress-bar *ngIf="saving" style="height: 5px; width: 100%;" mode="indeterminate"></mat-progress-bar>
        </div>

        <ul class="border password-param">
            <li>Minimum eight characters</li>
            <li>At least one upper case letter</li>
            <li>At least one lower case letter</li>
            <li>At least one digit</li>
            <li>At least one special of the following special character<br/>#?!@$^&*-</li>
        </ul>

        <div class="flex-row">
            <button mat-raised-button color="primary" *ngIf="!saving" [disabled]="loading || !changePasswordForm.valid" class="btn btn-primary btn-size flex-3" type="submit">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Submit
          </button>
        </div>
    </form>
    <div class="flex-1"></div>
</div>