import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ServiceProviderService } from 'src/app/services/service-provider.service';
import { TokenStorageService } from 'src/app/services/token_storage.service';

@Component({
	selector: 'app-update-photo-dialog',
	templateUrl: './update-photo-dialog.component.html',
	styleUrls: ['./update-photo-dialog.component.scss']
})
export class UpdatePhotoDialogComponent implements OnInit {
	nonAcceptedFileType: boolean = true;
	uploadServerError: boolean = true;
	selectedFile!: File;
	loading: boolean = false;
	errorMessage: string | undefined;
	updatedPhoto: boolean = false;

	constructor(
		private serviceProviderService: ServiceProviderService,
		private userService: TokenStorageService,
		public dialogRef: MatDialogRef<UpdatePhotoDialogComponent>,

	) { }

	ngOnInit() {
	}

	selectFile(event: any): void {
		this.nonAcceptedFileType = false;
		this.uploadServerError = false;
		var file = event.target.files[0];;
		let isAllowedFileExt: boolean = false;
		const ext = file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length).toLocaleLowerCase() || undefined;

		switch (ext) {
			case 'jpg':
			case 'jpeg':
			case 'png':
				isAllowedFileExt = true;
				break;
			default:
				alert('Non-Accepted File Type');
		}

		if (isAllowedFileExt) {
			this.selectedFile = event.target.files[0];
			this.uploadFile();
		} else {
			this.nonAcceptedFileType = true;
		}
	}

	uploadFile(): void {
		this.loading = true;
		this.serviceProviderService.uploadFile(this.selectedFile, this.userService.getUser().id).subscribe(() => {
			this.updatedPhoto = true;
			this.loading = false;
			this.closeDialog();
		}, () => this.errorMessage = 'Failed to Update Photo');
	}

	closeDialog(): void {
		this.dialogRef.close(this.updatedPhoto);
	}
}
