<div class="content scrollable-table">
    <h1 class="mt-3">Service Plans</h1>
    <table mat-table [dataSource]="servicePlans" class="mat-elevation-z8">
        <ng-container matColumnDef="Service Type">
            <th mat-header-cell *matHeaderCellDef>Service Type</th>
            <td mat-cell *matCellDef="let element">
                {{ element.serviceTypeModel.name }}
            </td>
        </ng-container>

        <ng-container matColumnDef="Service Description">
            <th mat-header-cell *matHeaderCellDef>Service Description</th>
            <td mat-cell *matCellDef="let element">{{ element.description }}</td>
        </ng-container>

        <ng-container matColumnDef="Active">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">{{ element.servicePlanStatus }}</td>
        </ng-container>

        <ng-container matColumnDef="Quantity Available">
            <th mat-header-cell *matHeaderCellDef>Quantity Available</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="element.unlimitedQuantity">Unlimited</div>
                <div *ngIf="!element.unlimitedQuantity">{{element.quantityAvailable}}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="Unlimited Quantity">
            <th mat-header-cell *matHeaderCellDef>Unlimited Quantity</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="element.unlimitedQuantity">Yes</div>
                <div *ngIf="!element.unlimitedQuantity">No</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="Rate">
            <th mat-header-cell *matHeaderCellDef>Rate</th>
            <td mat-cell *matCellDef="let element">${{ element.rate.toFixed(2) }}</td>
        </ng-container>

        <ng-container matColumnDef="Appointment Type">
            <th mat-header-cell *matHeaderCellDef>Appointment Type</th>
            <td mat-cell *matCellDef="let element">{{ element.appointmentType }}</td>
        </ng-container>

        <ng-container matColumnDef="Edit">
            <th mat-header-cell *matHeaderCellDef>Edit</th>
            <td mat-cell *matCellDef="let element">
                <mat-icon color="warn" (click)="openEditServicePlanDialog(element)" class="clickable">edit</mat-icon>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No Services</td>
        </tr>
    </table>
</div>