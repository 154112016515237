import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { ChangePasswordService } from 'src/app/services/change_password_service.service';
import { TokenStorageService } from 'src/app/services/token_storage.service';

@Component({
    selector: 'change-password',
    templateUrl: './change_password.component.html',
    styleUrls: ['./change_password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

    changePasswordForm = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/)]),
      confirmPassword: new FormControl(''),
    });

    get formControls(): { [p: string]: AbstractControl } { return this.changePasswordForm.controls; }

    loading = false;
    saving = false;
    error = '';

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private changePasswordService: ChangePasswordService,
        private tokenStorage: TokenStorageService,
    ) { }

    ngOnInit(): void {
      this.tokenStorage.clearToken();
      this.changePasswordForm.setValidators(this.samePasswords(this.changePasswordForm));
      this.changePasswordForm.controls.confirmPassword.setValidators([Validators.required, this.samePasswords(this.changePasswordForm)]);
    }

  samePasswords(formGroup: FormGroup): ValidatorFn {
    return (): ValidationErrors | null => {
      const password = formGroup.controls.password;
      const confirmPassword = formGroup.controls.confirmPassword;
      return password.value !== confirmPassword.value &&
      (password.dirty && confirmPassword.dirty) &&
      (password.value !== '' && confirmPassword.value !== '')
        ? {notSame: true}
        : null;
    };
  }

    onSubmit(): void {
        const changePasswordId = this.activatedRoute.snapshot.paramMap.get('id');

        if (changePasswordId != null) {
            this.saving = true;

            const password = this.formControls.password.value;

            this.changePasswordService.post([], { password, changePasswordId }).subscribe(
                x => {
                    this.saving = false;
                    this.router.navigate(['login']);
                },
                (error) => {
                    this.saving = false;
                    this.error = error;
                }
            );
        } else {
            this.error = 'Invalid change request, try sending another link to your email.';
        }
    }
}
