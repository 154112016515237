<div class="flex-row">
    <div class="flex-1"></div>

    <div class="flex-3 margin-top-20">
        <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()" class="form-content">
            <h1 class="margin-bottom-25 center-text mt-3">Create a Provider Account</h1>

            <mat-form-field>
                <mat-label>First Name</mat-label>
                <input type="text" matInput formControlName="firstName">
                <mat-error *ngIf="formControls.firstName.hasError('required')">Name is required.</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Last Name</mat-label>
                <input type="text" matInput formControlName="lastName">
                <mat-error *ngIf="formControls.lastName.hasError('required')">Name is required.</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Business Name</mat-label>
                <input type="text" matInput formControlName="businessName">
                <mat-error *ngIf="formControls.businessName.hasError('required')">Business name is required.</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Email Address</mat-label>
                <input (change)="checkProperEmail()" type="email" matInput formControlName="emailAddress">

                <mat-error *ngIf="formControls.emailAddress.hasError('required')">Email is required.</mat-error>
            </mat-form-field>
            <p class="email-warn" *ngIf="!properEmail && !formControls.emailAddress.pristine">Email is invalid.</p>

            <mat-form-field>
                <mat-label>Address</mat-label>
                <input type="text" matInput formControlName="address">
                <mat-error *ngIf="formControls.address.hasError('required')">Address is required.</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>City</mat-label>
                <input type="text" matInput formControlName="city">
                <mat-error *ngIf="formControls.city.hasError('required')">City is required.</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>State</mat-label>
                <mat-select (change)="selectState($event)" formControlName="state">
                    <mat-option *ngFor="let state of states" [value]="state">{{state}}</mat-option>
                </mat-select>
                <mat-error *ngIf="formControls.state.hasError('required')">State is required.</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Zip Code</mat-label>
                <input type="text" matInput formControlName="zipCode" minlength="5" maxlength="5">
                <mat-error *ngIf="formControls.zipCode.hasError('required')">Zip code is required.</mat-error>
                <mat-error *ngIf="formControls.zipCode.hasError('minlength')">Zip code must be 5 digits.</mat-error>
                <mat-error *ngIf="formControls.zipCode.hasError('maxlength')">Zip code must be 5 digits.</mat-error>
                <mat-error *ngIf="formControls.zipCode.hasError('pattern')">Zip code is invalid.</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Primary Phone</mat-label>
                <input type="text" matInput formControlName="primaryPhoneNumber" minlength="12" maxlength="12">
                <mat-hint>Ex.123-123-1234</mat-hint>
                <mat-error *ngIf="formControls.primaryPhoneNumber.hasError('required')">Primary phone number is required.</mat-error>
                <mat-error *ngIf="formControls.primaryPhoneNumber.hasError('minlength')">Must be 12 Characters,<br>Ex. 123-123-1234</mat-error>
                <mat-error *ngIf="formControls.primaryPhoneNumber.hasError('pattern')">Invalid Phone Number</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Secondary Phone</mat-label>
                <input type="text" matInput formControlName="secondaryPhoneNumber" maxlength="12">
                <mat-hint>Ex.123-123-1234</mat-hint>
                <mat-error *ngIf="formControls.secondaryPhoneNumber.hasError('pattern')">Invalid Phone Number</mat-error>
            </mat-form-field>

            <div *ngIf="errorMessage !== null" class="error-text">
                <p>{{errorMessage}}</p>
            </div>

            <div class="form-group float-right width-100">
                <mat-progress-bar *ngIf="saving" style="height: 8px; width: 100%;" mode="indeterminate"></mat-progress-bar>

                <button mat-raised-button color="primary" *ngIf="!saving" [disabled]="loading || !registrationForm.valid || !properEmail" class="btn mt-2 btn-primary btn-size" type="submit">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Register
                </button>
            </div>
        </form>
    </div>

    <div class="flex-4 image-container">
        <h1>IN AN UPPER WORLD</h1>

        <img src={{registrationImage}}>
    </div>
</div>