import { Component, OnInit } from '@angular/core';
import { UserSubscription } from 'src/app/models/user-subscription.model';
import { TokenStorageService } from 'src/app/services/token_storage.service';
import { UserSubscriptionService } from 'src/app/services/user-subscription.service';

@Component({
	selector: 'app-subscriber-management',
	templateUrl: './subscriber-management.component.html',
	styleUrls: ['./subscriber-management.component.scss']
})
export class SubscriberManagementComponent implements OnInit {

	subscriptions: UserSubscription[] = [];

	constructor(
		private userSubscriptionService: UserSubscriptionService,
		private tokenService: TokenStorageService,
	) { }

	ngOnInit() {
		this.getAllSubscriptions();
	}

	getAllSubscriptions(): void {
		const queryParams = new Map([
			['providerId', this.tokenService.getUser().id],
			['isActive', true]
		]);

		this.userSubscriptionService.get(['SubscriptionsByProvider'], queryParams).subscribe((result) => {
			this.subscriptions = result;
		});
	}

}
