<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<h1 class="title">Sales Records</h1>
<div class="table-container">
    <table mat-table [dataSource]="orders" class="table">
        <ng-container matColumnDef="Service Plan">
            <th mat-header-cell *matHeaderCellDef>Service Plan</th>
            <td mat-cell *matCellDef="let element">
                {{ element.servicePlan.description }}
            </td>
        </ng-container>

        <ng-container matColumnDef="Customer">
            <th mat-header-cell *matHeaderCellDef>Customer</th>
            <td mat-cell *matCellDef="let element">
                {{ element.order.userModel.firstName }} {{ element.order.userModel.lastName }}
            </td>
        </ng-container>

        <ng-container matColumnDef="Date of Purchase">
            <th mat-header-cell *matHeaderCellDef>Date of Purchase</th>
            <td mat-cell *matCellDef="let element">
                {{ element.order.updateDateUTC | date: "shortDate" }}
            </td>
        </ng-container>

        <ng-container matColumnDef="Total Sale">
            <th mat-header-cell *matHeaderCellDef>Total Sale</th>
            <td mat-cell *matCellDef="let element">
                ${{ element.total.toFixed(2) }}
            </td>
        </ng-container>

        <ng-container matColumnDef="Amount Paid To Provider">
            <th mat-header-cell *matHeaderCellDef>Provider Earnings</th>
            <td mat-cell *matCellDef="let element">
                ${{ getAmountPaidToProvider(element).toFixed(2)}}
            </td>
        </ng-container>

        <tr class="mat-row mt-2" *matNoDataRow>
            <td class="mt-2" [attr.colspan]=" displayedColumns.length">
                No Sales Records
            </td>
        </tr>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>