<h1 mat-dialog-title class="font-warn">Warning: Changes can effect current customers plans</h1>

<div mat-dialog-content>
    <h3>Subscriptions:</h3>
    If toggled to inactive, all current users plans will be canceled -
    <p>You will be liable to cover current paid for plans until each customers subscription ends</p>
    <p>These plans can be toggled back on, but all customers will have to re-subscribe to plan</p>

    <mat-divider class="mb-3"></mat-divider>

    <h3>One Time Payment Service Plans:</h3>

    <p> If toggled to inactive, you will be liable to cover all purchased service plans prior to inactivation</p>
    <p>These plans can be toggled back on at a later date</p>

    <mat-divider class="mb-3"></mat-divider>

    <h3>For all previously purchased plans:</h3>
    <p>You will be required to fulfill all previously purchased service plans based off of set values on time of purchase</p>
    <p>This includes prices, appointment locations, appointment availability, and all other details set on application</p>
</div>


<div mat-dialog-actions class="float-right">
    <mat-checkbox class="mr-3" color="primary" [formControl]="agreeToTerms">Understand Terms</mat-checkbox>
    <button mat-raised-button [mat-dialog-close]="this.agreeToTerms.value" [disabled]="!this.agreeToTerms.value" color="primary" mat-dialog-close>Agree</button>
    <button mat-raised-button [mat-dialog-close]="false" color="primary" mat-dialog-close>Cancel</button>

</div>