<button class="update-info-btn" (click)="navigateToProfileManagement()" mat-raised-button color="primary">Update Info</button>

<div *ngIf="!loading" class="content flex-row">
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

    <mat-card class="image-card">
        <mat-card-header>
            <button class="update-photo-btn" (click)="openUpdatePhotoDialog()" mat-raised-button color="primary">Update Photo</button>
        </mat-card-header>

        <div class="profile-image-container">
            <img class="profile-image" [src]="profileImage" alt="Profile Image">
        </div>

        <mat-divider class="ml-2" style="width: 95%;"></mat-divider>

        <mat-card-content class="profile-card-content">
            <h1>{{serviceProvider.firstName + " " + serviceProvider.lastName}}</h1>

            <h3 *ngIf="serviceProvider.businessName != null">{{serviceProvider.businessName}}</h3>
        </mat-card-content>
    </mat-card>

    <div class="data">
        <mat-card class="data-card card">
            <mat-card-title>{{serviceProvider.firstName + "'s "}}Information</mat-card-title>

            <mat-divider class="ml-3 mt-2" style="width: 95%;"></mat-divider>

            <div class="flex-row mt-5 data-card-info">
                <h1>Location: </h1>
                <h3 style="margin-left: 50px; min-width: 150px;">{{serviceProvider.city + ', ' + serviceProvider.state}}</h3>
            </div>

            <mat-divider class="ml-3 mt-2" style="width: 95%;"></mat-divider>

            <div class="flex-row mt-5 data-card-info">
                <h1>Email: </h1>
                <h3 style="margin-left: 50px; min-width: 150px;">{{serviceProvider.emailAddress}}</h3>
            </div>
        </mat-card>

        <mat-card class="bio-card card">
            <mat-card-title>Bio</mat-card-title>

            <mat-divider class="ml-3" style="width: 95%;"></mat-divider>

            <p class="mt-3">{{serviceProvider.profileBio}}</p>
        </mat-card>
    </div>
</div>

<mat-divider class="mt-5 mb-2" style="width: 70%; margin-left: 12%;"></mat-divider>

<h1 style="margin-left: 10%;">ServicePlans <button (click)="navigateToServiceManagement()" mat-raised-button color="primary">Update Service Plans</button></h1>

<div class="content shopping-card-content flex-row">
    <mat-card *ngFor="let servicePlan of servicePlans" class="ml-2 mb-1 shopping-card">
        <mat-card-header style="min-height: 150px;">
            <mat-card-title>{{servicePlan.serviceTypeModel?.name}}</mat-card-title>

            <mat-card-subtitle>{{servicePlan.description}}</mat-card-subtitle>
        </mat-card-header>

        <mat-divider class="ml-2 mt-4" style="width: 95%;"></mat-divider>

        <mat-card-content class="ml-3 mt-5">
            <p>Rate: ${{ servicePlan.rate.toFixed(2) }}</p>

            <p>Appointment Type: {{ servicePlan.appointmentType }}</p>
        </mat-card-content>

        <mat-divider class="ml-2" style="width: 95%;"></mat-divider>

        <mat-card-actions>
            <button disabled mat-raised-button class="ml-3 mt-3" color="primary">Shop Now</button>
        </mat-card-actions>
    </mat-card>
</div>

<footer class="mt-5"></footer>