import { Component, OnInit } from '@angular/core';
import { OrderServicePlan } from 'src/app/models/order_service_plan.model';
import { ProviderPaymentService } from 'src/app/services/provider_payment.service';
import { TokenStorageService } from 'src/app/services/token_storage.service';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss']
})
export class SalesComponent implements OnInit {
  orders: OrderServicePlan[] = [];
  displayedColumns: string[] = ['Service Plan', 'Customer', 'Date of Purchase', 'Total Sale', 'Amount Paid To Provider'];
  loading: boolean = false;
  transactionalPercentage!: number;


  constructor(
    private paymentService: ProviderPaymentService,
    private tokenStorageService: TokenStorageService
  ) { }

  ngOnInit(): void {
    this.getProviderPayments();
  }

  getProviderPayments() {
    this.loading = true;
    const emptyQueryParams = new Map<string, any>([]);
    this.paymentService.get([this.tokenStorageService.getUser().id], emptyQueryParams).subscribe((result) => {
      this.orders = result.orderServicePlans;
      this.transactionalPercentage = result.transactionPercent;
      this.loading = false;
    });
  }

  getAmountPaidToProvider(orderServicePlan: OrderServicePlan): number {
    return orderServicePlan.total - (orderServicePlan.total * (this.transactionalPercentage / 100));
  }

}
