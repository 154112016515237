import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TokenStorageService } from 'src/app/services/token_storage.service';

@Component({
    selector: 'navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent {
    opened = false;
    upperNewsLink: string  = 'https://ontheupper.com/'

    routes = new Map([
        ['application', 'Applications'],
        ['settings', 'Your Account'],
    ]);

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private tokenStorage: TokenStorageService,
    ) {}

    logout(): void {
        sessionStorage.clear();
        this.router.navigate(['login']);
    }

    toggleSidenav(): void {
        this.opened = !this.opened;
    }

    get showSidenav(): boolean {

        if (!this.isUserLoggedIn) {
            return false;
        } else {
            return this.opened;
        }
    }


    get isUserLoggedIn(): boolean {
        if (this.tokenStorage.getUser() != null && this.tokenStorage.getToken() != null) {
            return true;
        }
        return false;
    }

    navigateToSettings(): void {
        this.router.navigate(['settings']);
    }
}
