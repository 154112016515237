import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ServiceApplication } from 'src/app/models/service_application.model';
import { ServiceApplicationService } from 'src/app/services/service_application_service';

@Component({
    selector: 'application',
    templateUrl: './application.component.html',
    styleUrls: ['./application.component.scss'],
})
export class ApplicationComponent implements OnInit {

    loading = false;
    columnHeaders: string[] = ['name', 'applicationStatus', 'dateCreated', 'action'];

    serviceApplications: ServiceApplication[] = [];
    serviceApplicationDataSource = new MatTableDataSource<ServiceApplication>([]);

    get activeServiceApplicationExists(): boolean {
        return this.serviceApplications.filter(app =>
            app.serviceApplicationStatus === 'Started' ||
            app.serviceApplicationStatus === 'Submitted' ||
            app.serviceApplicationStatus === 'Approved'
        ).length > 0;
    }

    constructor(
        private router: Router,
        private serviceApplicationService: ServiceApplicationService,
    ) { }

    ngOnInit(): void {
        this.refreshTable();
    }

    refreshTable(): void {
        this.loading = true;
        this.serviceApplicationService.getAll(['all'], new Map()).subscribe(serviceApplications => {
                this.serviceApplications = serviceApplications;
                this.serviceApplicationDataSource.data = serviceApplications;
                this.loading = false;
            }, (_) => this.loading = false
        );
    }

    createServiceApplication(id?: string): void {
        if (id != null) {
            this.router.navigate(['application', id]);
        } else {
            this.serviceApplicationService.post([], {}).subscribe(newServiceApplication => {
                this.router.navigate(['application', newServiceApplication.serviceApplicationId]);
            });
        }
    }
}
