<div class="flex-row margin-tb-20">
    <div class="flex-1"></div>

    <mat-accordion class="headers-align flex-4" multi>
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title> Service Types </mat-panel-title>
                <mat-panel-description>
                    Select Service Types
                    <mat-icon>person</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div>
                <form class="service-type-form flex-column">
                    <div class="flex-row">
                        <mat-form-field class="flex-1">
                            <mat-label>Service Type</mat-label>

                            <input type="text" placeholder="Filter by name..." aria-label="Care Type" matInput [formControl]="serviceTypeFormControl" autocomplete="off" />

                            <button type="button" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearServiceTypeFilter()">
                <mat-icon>close</mat-icon>
              </button>
                        </mat-form-field>

                        <div class="flex-2"></div>
                    </div>
                </form>

                <mat-progress-bar *ngIf="loadingServiceTypes" mode="indeterminate"></mat-progress-bar>

                <div class="scrollable-table">
                    <table mat-table [dataSource]="servicePlanDataSource" class="service-table mat-elevation-z8">
                        <ng-container matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef>Type</th>
                            <td mat-cell *matCellDef="let selectedServicePlan" class="padding-5">
                                {{ selectedServicePlan.serviceTypeModel.name }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let selectedServicePlan; let j = index" class="padding-5">
                                <button mat-raised-button color="dark" aria-label="Add Service Plan" (click)="createServiceApplicationServicePlanDialog(selectedServicePlan)" class="float-right">Add</button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="servicePlanColumnHeaders"></tr>
                        <tr mat-row *matRowDef="let row; columns: servicePlanColumnHeaders"></tr>

                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4">No care types found.</td>
                        </tr>
                    </table>
                </div>

                <h3 class="underlined margin-top-30">My Service Plans</h3>

                <mat-progress-bar *ngIf="loadingServiceApplicationServicePlans" mode="indeterminate"></mat-progress-bar>

                <div class="scrollable-table">
                    <table mat-table [dataSource]="serviceApplicationServicePlanDataSource" class="service-table mat-elevation-z8">
                        <ng-container matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef>Type</th>
                            <td mat-cell *matCellDef="let selectedServiceApplicationServicePlan" class="padding-5">
                                {{ selectedServiceApplicationServicePlan.servicePlanModel.serviceTypeModel.name }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="description">
                            <th mat-header-cell *matHeaderCellDef class="padding-5">
                                Description
                            </th>
                            <td mat-cell *matCellDef="let selectedServiceApplicationServicePlan" class="padding-5">
                                {{ selectedServiceApplicationServicePlan.servicePlanModel .description }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="
                                let selectedServiceApplicationServicePlan;
                                let j = index" class="padding-5">
                                <button mat-stroked-button color="warn" aria-label="Delete Care Plan" (click)="deleteServiceApplicationServicePlan(selectedServiceApplicationServicePlan)" class="float-right">Delete</button>
                                <button mat-stroked-button aria-label="Delete Care Plan" (click)="editServiceApplicationServicePlanDialog(selectedServiceApplicationServicePlan)" class="float-right margin-right-10">Edit</button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="selectedServicePlanColumnHeaders"></tr>
                        <tr mat-row *matRowDef="let row; columns: selectedServicePlanColumnHeaders"></tr>

                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4">No care plans exist.</td>
                        </tr>
                    </table>

                    <div class="paginator">
                        <mat-paginator [length]="selectedServicePlanTotalItemCount"
                                       [pageSize]="10"
                                       (page)="changePage($event)"
                                       aria-label="Select page">
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title> Files </mat-panel-title>
                <mat-panel-description>
                    Upload files
                    <mat-icon>attach_file</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <form [formGroup]="dateFormGroup">
                <h3 class="underlined">
                    Required Files:
                    <div style="margin-left: 15px">
                        Please Select Issued Date and Expiration Date before Choosing File
                        <span class="flex-0 file-warn" *ngIf="nonAcceptedFileType">File Type Not Supported</span
            >
            <span class="flex-0 file-warn" *ngIf="uploadServerError">{{
              uploadServerMessage
            }}</span>
                    </div>
                </h3>

                <div class="flex-row">
                    <mat-icon class="mr-2">account_box</mat-icon>

                    <div class="flex-3">
                        <h3>Upload a Front and Back Copy of an ID</h3>
                    </div>
                    <div>
                        <mat-form-field appearance="fill" class="date-form-field">
                            <mat-label>Issued Date</mat-label>
                            <input matInput [matDatepicker]="idIssuedDate" formControlName="idIssuedDate" />
                            <mat-datepicker-toggle matSuffix [for]="idIssuedDate"></mat-datepicker-toggle>
                            <mat-datepicker #idIssuedDate></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="date-form-field mr-3">
                            <mat-label>Expiration Date</mat-label>
                            <input matInput [matDatepicker]="idExpirationDate" formControlName="idExpirationDate" />
                            <mat-datepicker-toggle matSuffix [for]="idExpirationDate"></mat-datepicker-toggle>
                            <mat-datepicker #idExpirationDate></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div class="file-upload-container flex-1 mb-3">
                        <input #fileInput id="file" [disabled]="dateFormGroup.controls.idIssuedDate.pristine || dateFormGroup.controls.idExpirationDate.pristine" style="align-self: center" type="file" accept=".pdf,.doc,.docx,.png,.jpg,.jpeg" (change)="selectFile($event, id)"
                        />
                    </div>
                </div>

                <mat-divider></mat-divider>

                <div class="flex-row mt-4">
                    <mat-icon class="mr-2">card_membership</mat-icon>

                    <div class="flex-3">
                        <h3>Please Upload any Relevant Licenses and/or Certificates</h3>
                    </div>
                    <div>
                        <mat-form-field appearance="fill" class="date-form-field">
                            <mat-label>Issued Date</mat-label>
                            <input matInput [matDatepicker]="certificateIssuedDate" formControlName="certificateIssuedDate" />
                            <mat-datepicker-toggle matSuffix [for]="certificateIssuedDate"></mat-datepicker-toggle>
                            <mat-datepicker #certificateIssuedDate></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="date-form-field mr-3">
                            <mat-label>Expiration Date</mat-label>
                            <input matInput [matDatepicker]="certificateExpirationDate" formControlName="certificateExpirationDate" />
                            <mat-datepicker-toggle matSuffix [for]="certificateExpirationDate"></mat-datepicker-toggle>
                            <mat-datepicker #certificateExpirationDate></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="file-upload-container flex-1 mb-3">
                        <input #fileInput id="file" [disabled]="dateFormGroup.controls.certificateIssuedDate.pristine || dateFormGroup.controls.certificateExpirationDate.pristine" style="align-self: center" type="file" accept=".pdf,.doc,.docx,.png,.jpg,.jpeg" (change)="selectFile($event, license)"
                        />
                    </div>
                </div>

                <mat-divider></mat-divider>

                <div class="flex-row mt-4">
                    <mat-icon class="mr-2">article</mat-icon>

                    <div class="flex-3">
                        <h3>Please Upload Certificate of Insurance</h3>
                    </div>

                    <div>
                        <mat-form-field appearance="fill" class="date-form-field">
                            <mat-label>Issued Date</mat-label>
                            <input matInput [matDatepicker]="insuranceIssuedDate" formControlName="insuranceIssuedDate" />
                            <mat-datepicker-toggle matSuffix [for]="insuranceIssuedDate"></mat-datepicker-toggle>
                            <mat-datepicker #insuranceIssuedDate></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="mr-3 date-form-field">
                            <mat-label>Expiration Date</mat-label>
                            <input matInput [matDatepicker]="insuranceExpirationDate" formControlName="insuranceExpirationDate" />
                            <mat-datepicker-toggle matSuffix [for]="insuranceExpirationDate"></mat-datepicker-toggle>
                            <mat-datepicker #insuranceExpirationDate></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="file-upload-container flex-1 mb-3">
                        <input #fileInput id="file" [disabled]="dateFormGroup.controls.insuranceIssuedDate.pristine || dateFormGroup.controls.insuranceExpirationDate.pristine" style="align-self: center" type="file" accept=".pdf,.doc,.docx,.png,.jpg,.jpeg" (change)="selectFile($event, insurance)"
                        />
                    </div>
                </div>

                <mat-divider></mat-divider>

                <h3 class="underlined">
                    <span matTooltip="These files are part of your user profile. To link or unlink them from your service application click the icon in the table.">
                        <span *ngIf="serviceApplication != null">{{serviceApplication?.serviceProviderModel?.firstName + " " + serviceApplication?.serviceProviderModel?.lastName}}</span>
                    <span *ngIf="serviceApplication == null">Provider</span>
                    </span>
                </h3>

                <mat-progress-bar *ngIf="loadingServiceDocuments || loadingServiceApplicationServiceDocuments" mode="indeterminate"></mat-progress-bar>

                <div class="scrollable-table">
                    <table mat-table [dataSource]="serviceDocumentDataSource" class="service-table mat-elevation-z8">
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef>Name</th>
                            <td mat-cell *matCellDef="let serviceDocument">
                                {{ serviceDocument.name }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="dateCreated">
                            <th mat-header-cell *matHeaderCellDef>File Type</th>
                            <td mat-cell *matCellDef="let serviceDocument">
                                {{ serviceDocument.contentType }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="fileCategory">
                            <th mat-header-cell *matHeaderCellDef>File Category</th>
                            <td mat-cell *matCellDef="let serviceDocument">
                                {{ serviceDocument.fileCategory }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="issuedDate">
                            <div>
                                <th mat-header-cell *matHeaderCellDef>Issued Date</th>
                                <td mat-cell *matCellDef="let serviceDocument">
                                    {{ serviceDocument.issuedDate | date: "shortDate" }}
                                </td>
                            </div>
                        </ng-container>

                        <ng-container matColumnDef="expirationDate">
                            <th mat-header-cell *matHeaderCellDef>Expiration Date</th>
                            <td mat-cell *matCellDef="let serviceDocument">
                                {{ serviceDocument.expirationDate | date: "shortDate" }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let serviceDocument; let j = index">
                                <button *ngIf="!isServiceDocumentLinked(serviceDocument.serviceDocumentId)" mat-raised-button color="dark" style="width: 70px;" class="float-right mb-1" aria-label="Add to current application" matTooltip="Add file to this application" (click)="createServiceApplicationServiceDocument(serviceDocument.serviceDocumentId)">Attach</button>

                                <button *ngIf="isServiceDocumentLinked(serviceDocument.serviceDocumentId)" mat-stroked-button color="warn" style="padding-left: 8px !important; width: 70px;" class="float-right mb-1" aria-label="Remove from current application" matTooltip="Remove file from this application"
                                    (click)="deleteServiceApplicationServiceDocument(serviceDocument.serviceDocumentId)">Remove</button>

                                <button mat-raised-button color="dark" class="float-right mb-1" style="width: 70px" aria-label="ViewFile" matTooltip="View File" (click)="viewFile(serviceDocument)">View</button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="serviceDocumentColumnHeaders"></tr>
                        <tr mat-row *matRowDef="let row; columns: serviceDocumentColumnHeaders"></tr>

                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4">No files for this provider.</td>
                        </tr>
                    </table>
                </div>
            </form>
        </mat-expansion-panel>
        <form [formGroup]="termsAndConditions">
            <mat-accordion>
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Please Read Terms and Conditions
                        </mat-panel-title>

                        <mat-panel-description>
                            *Must mark at bottom of terms and conditions that they have been read
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <a [href]="termsLink" target="_blank">Agree To Terms and Conditions</a>
                    <div class="mt-3">
                        <mat-checkbox formControlName="agreeToTerms">Check box to agree with terms and conditions</mat-checkbox>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </form>
        <div class="button-full-width">
            <mat-progress-bar *ngIf="savingServiceApplication" mode="indeterminate" style="width: 100%"></mat-progress-bar>

            <button *ngIf="!savingServiceApplication" [disabled]="serviceApplicationServicePlans?.length == 0 || !termsAndConditions.controls.agreeToTerms.value" mat-raised-button color="primary" class="button-full-width margin-top-30" (click)="onSubmitApplication()">
        Submit
      </button>
        </div>
    </mat-accordion>

    <div class="flex-1"></div>
</div>