import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceProviderService } from 'src/app/services/service-provider.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  constructor(
    private router: Router,
    private providerService: ServiceProviderService
  ) { }

  ngOnInit() {
    // this.providerService.post(['create-connected-account'], {}).subscribe(response => console.log(response));
  }

  navigateToUpdateACH(): void {
    this.router.navigate(['update-ach']);
  }

  navigateToServiceManagement(): void {
    this.router.navigate(['service-management']);
  }

  navigateToApplications(): void {
    this.router.navigate(['application']);
  }

  navigateToProfileManagement(): void {
    this.router.navigate(['profile-management']);
  }

  navigateToSubscribers(): void {
    this.router.navigate(['subscriber-management'])
  }

  navigateToProfile(): void {
    this.router.navigate(['profile'])
  }

  navigateToSales(): void {
    this.router.navigate(['sales'])
  }
}
