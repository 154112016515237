<h1 mat-dialog-title>{{data.serviceTypeModel.name}}</h1>
<p>*Note: Quantity value will just be an initial value, you can adjust as needed on profile after service is reviewed</p>
<div mat-dialog-content class="flex-column">

    <div class="checkbox-margin">
        <mat-checkbox color="primary" [(ngModel)]="data.inOfficeAvailability">In Office</mat-checkbox>
        <mat-checkbox color="primary" [(ngModel)]="data.virtualAvailability">Virtual</mat-checkbox>
        <mat-checkbox color="primary" [(ngModel)]="data.homeCareAvailability">At Home</mat-checkbox>
    </div>

    <div class="flex-row">
        <mat-form-field class="margin-lr-10 description-form-field-width">
            <mat-label>Description of Service</mat-label>

            <input matInput type="text" [(ngModel)]="data.description" placeholder=" i.e. Initial Assessment or name of your appointment..." maxlength="60" autocomplete="off">
        </mat-form-field>
    </div>

    <div class="flex-row">
        <mat-form-field class="margin-lr-10">
            <mat-label>Cost per Appointment</mat-label>

            <span matPrefix>$ &nbsp;</span>

            <input matInput type="number" [(ngModel)]="data.rate" min="0" autocomplete="off" appTwoDecimalDirective>
        </mat-form-field>

        <mat-form-field class="margin-lr-10">
            <mat-label>Appointment Type</mat-label>

            <mat-select [(ngModel)]="data.appointmentType" name="appointmentType" (selectionChange)="checkAppointmentType()">
                <mat-option *ngFor="let rateType of rateTypes | keyvalue" [value]="rateType.key">
                    {{rateType.value}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="margin-lr-10">
            <mat-label>Quantity for Sale</mat-label>

            <span matPrefix>&nbsp;</span>
            <input matInput type="number" [(ngModel)]="data.quantityAvailable" min="0" autocomplete="off" DigitOnlyDirective NoDecimalDirective>
        </mat-form-field>

        <mat-form-field *ngIf="data.appointmentType == 'Subscription'" class="margin-lr-10">
            <mat-label>Payment Occurrence</mat-label>

            <mat-select [(ngModel)]="data.paymentOccurrence" name="Payment Occurrence">
                <mat-option *ngFor="let paymentOccurrence of paymentOccurrenceTypes | keyvalue" [value]="paymentOccurrence.key">
                    {{paymentOccurrence.key}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <ul style="list-style-type:none; margin:2px;">
            <li>
                <mat-checkbox color="primary" class="" [(ngModel)]="data.unlimitedQuantity">Unlimited Quantity?</mat-checkbox>
            </li>
            <li>
                <mat-checkbox color="primary" class="mt-3" [(ngModel)]="data.hsaApproved">HSA Approved?</mat-checkbox>
            </li>
        </ul>
    </div>
</div>

<div class="error" *ngIf="isDisabled">*Please make sure all fields are completed and set to proper values</div>
<div class="error" *ngIf="setQuantityOrUnlimited">*Must set quantity for sale or mark unlimited</div>
<div class="error" *ngIf="quantitySetWithUnlimitedChecked">*Quantity set with unlimited checked, quantity will be ignored </div>
<div class="error" *ngIf="checkForNegativeRate">*Must be positive value in cost</div>
<div class="error" *ngIf="checkForNegativeQuantity">*Must be positive value in quantity</div>
<div class="error" *ngIf="checkQuantityMax">*Max quantity is 1000, please set to unlimited</div>
<div class="error" *ngIf="checkForToLargeCost">*Max rate is $100,000</div>



<div mat-dialog-actions class="float-right">
    <button mat-raised-button (click)="onCancelClick()">Cancel</button>
    <button mat-raised-button [mat-dialog-close]="data" [disabled]="isDisabled" cdkFocusInitial>Save</button>
</div>