import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Registration} from 'src/app/models/registration.model';
import {RegistrationService} from 'src/app/services/registration_service.service';
import { TokenStorageService } from 'src/app/services/token_storage.service';


@Component({
  selector: 'registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit{

  properEmail: boolean = false;

  registrationForm = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    businessName: new FormControl('', Validators.required),
    emailAddress: new FormControl('', [Validators.required, Validators.email]),
    address: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    state: new FormControl('', Validators.required),
    zipCode: new FormControl('', [Validators.required, Validators.pattern('(^\\d{5}$)|(^\\d{9}$)|(^\\d{5}-\\d{4}$)')]),
    primaryPhoneNumber: new FormControl('', [Validators.required, Validators.pattern(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)]),
    secondaryPhoneNumber: new FormControl('', Validators.pattern(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)),
  });

  registrationImage = "../../../assets/images/upper5.png";

  states: string[] = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 
                      'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 
                      'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 
                      'LA', 'ME', 'MD', 'MA', 'MI', 'MN',
                      'MS', 'MO', 'MT', 'NE', 'NV', 'NH',
                      'NJ', 'NM', 'NY', 'NC', 'ND', 'OH',
                      'OK', 'OR', 'PA', 'RI', 'SC', 'SD',
                      'TN', 'TX', 'UT', 'VT', 'VA', 'WA',
                      'WV', 'WI', 'WY']

  loading = false;
  saving = false;
  errorMessage: string | null = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private registrationService: RegistrationService,
    private tokenStorage: TokenStorageService,
  ) {
  }

  ngOnInit(): void {
    this.tokenStorage.clearToken();
  }

  selectState(e: any): void {
    this.registrationForm.controls.state.setValue(e.target.value, {
      onlySelf: true
    }); 
  }

  checkProperEmail(): void {
    this.properEmail = false;
    if(this.registrationForm.controls.emailAddress.value.includes('.')){
      this.properEmail = true;
    }
  }

  get formControls(): { [p: string]: AbstractControl } {
    return this.registrationForm.controls;
  }

  get formValues(): any {
    return this.registrationForm.value;
  }

  onSubmit(): void {
    this.saving = true;

    const firstName = this.formValues.firstName;
    const lastName = this.formValues.lastName;
    const businessName = this.formValues.businessName;
    const emailAddress = this.formValues.emailAddress;
    const address = this.formValues.address;
    const city = this.formValues.city;
    const state = this.formValues.state;
    const zipCode = this.formValues.zipCode;
    const primaryPhoneNumber = this.formValues.primaryPhoneNumber;
    const secondaryPhoneNumber = this.formValues.secondaryPhoneNumber;

    this.errorMessage = null;

    this.registrationService
      .post(['Registration'], {
        firstName,
        lastName,
        businessName,
        emailAddress,
        address,
        city,
        state,
        zipCode,
        primaryPhoneNumber,
        secondaryPhoneNumber
      })
      .subscribe(
        (_) => {
          this.saving = false;
          this.router.navigate(['registration-confirmation']);
        },
        (error) => {
          this.errorMessage = error;
          this.saving = false;
        });
  }
}

