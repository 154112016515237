import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Registration } from '../models/registration.model';
import { AbstractService } from './abstract_service.service';

@Injectable({
    providedIn: 'root'
})
export class RegistrationService extends AbstractService {
  path = 'ServiceProvider';
  registrationPath = 'Registration';
}
