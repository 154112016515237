import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AchUpdateComponent } from './components/ach-update/ach-update.component';
import { ApplicationComponent } from './components/application/application.component';
import { ChangePasswordComponent } from './components/change_password/change_password.component';
import { ForgotPasswordComponent } from './components/forgot_password/forgot_password.component';
import { LoginComponent } from './components/login/login.component';
import { ProfileManagementComponent } from './components/profile-management/profile-management.component';
import { ProfilePageComponent } from './components/profile-page/profile-page.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { RegistrationConfirmationComponent } from './components/registration_confirmation/registration_confirmation.component';
import { SalesComponent } from './components/sales/sales.component';
import { ServiceManagementComponent } from './components/service-management/service-management.component';
import { SettingsComponent } from './components/settings/settings.component';
import { SubscriberManagementComponent } from './components/subscriber-management/subscriber-management.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { UpdateApplicationComponent } from './components/update_application/update_application.component';
import { AuthGuardService } from './services/auth_guard_service.service';
import { StripeSetupComponent } from './components/stripe-setup/stripe-setup.component';
import { StripeSetupFinishComponent } from './components/stripe-setup-finish/stripe-setup-finish.component';
import { StripeCompleteGuardService } from './services/stripe-complete-guard.service';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'profile-management',
    component: ProfileManagementComponent,
    canActivate: [AuthGuardService, StripeCompleteGuardService],
  },
  {
    path: 'registration',
    component: RegistrationComponent,
  },
  {
    path: 'registration-confirmation',
    component: RegistrationConfirmationComponent,
  },
  {
    path: 'change-password/:id',
    component: ChangePasswordComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'application',
    component: ApplicationComponent,
    canActivate: [AuthGuardService, StripeCompleteGuardService]
  },
  {
    path: 'sales',
    component: SalesComponent,
    canActivate: [AuthGuardService, StripeCompleteGuardService]
  },
  {
    path: 'service-management',
    component: ServiceManagementComponent,
    canActivate: [AuthGuardService, StripeCompleteGuardService]
  },
  {
    path: 'update-ach',
    component: AchUpdateComponent,
    canActivate: [AuthGuardService, StripeCompleteGuardService]
  },
  {
    path: 'subscriber-management',
    component: SubscriberManagementComponent,
    canActivate: [AuthGuardService, StripeCompleteGuardService],
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuardService, StripeCompleteGuardService],
  },
  {
    path: 'application/:id',
    component: UpdateApplicationComponent,
    canActivate: [AuthGuardService, StripeCompleteGuardService],
  },
  {
    path: 'terms',
    component: TermsAndConditionsComponent,
    canActivate: [AuthGuardService, StripeCompleteGuardService],
  },
  {
    path: 'profile',
    component: ProfilePageComponent,
    canActivate: [AuthGuardService, StripeCompleteGuardService],
  },
  {
    path: "stripe-setup",
    component: StripeSetupComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'stripe-setup-finalize',
    component: StripeSetupFinishComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: '',
    redirectTo: '/settings',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
