<h1>Edit Service Plan</h1>

<form [formGroup]="servicePlanFormGroup">
    <div class="flex-row">
        <div class="flex-0">
            <mat-form-field appearance="outline">
                <mat-label>Description</mat-label>
                <input matInput formControlName="description" type="text" />
                <mat-error *ngIf="servicePlanFormGroup.controls.description.hasError('maxlength')">Description limited to 120 Characters</mat-error>
            </mat-form-field>
        </div>

        <div class="flex-0 ml-2">
            <mat-form-field appearance="outline">
                <mat-label>Appointment Type</mat-label>
                <mat-select (selectionChange)="selectAppointmentType($event)" formControlName="appointmentType">
                    <mat-option *ngFor="let appointmentType of appointmentTypes" [value]="appointmentType">{{ appointmentType }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="flex-0 ml-2">
            <mat-form-field appearance="outline">
                <mat-label>Quantity Available</mat-label>
                <input matInput formControlName="quantityAvailable" type="number" max="10000" min="0" maxlength="5" appWholeNumberDirective/>
                <mat-error *ngIf="servicePlanFormGroup.controls.quantityAvailable.hasError('max')">Max Value 10,000, set to unlimited</mat-error>
            </mat-form-field>
        </div>

        <div class="flex-0 ml-2">
            <mat-form-field appearance="outline">
                <mat-label>Rate</mat-label>
                <input matInput formControlName="rate" type="number" appTwoDecimalDirective />
                <mat-error *ngIf="servicePlanFormGroup.controls.rate.hasError('max')">Max Rate $100,000</mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="flex-row">
        <div class="flex-0 ml-2 mt-3">
            <mat-checkbox matInput formControlName="inOfficeAvailability" color="primary">Office Availability</mat-checkbox>
        </div>

        <div class="flex-0 ml-2 mt-3">
            <mat-checkbox matInput formControlName="homeCareAvailability" color="primary">Home Availability</mat-checkbox>
        </div>

        <div class="flex-0 ml-2 mt-3">
            <mat-checkbox matInput formControlName="virtualAvailability" color="primary">Virtual Availability</mat-checkbox>
        </div>

        <div class="flex-0 ml-2 mt-3">
            <mat-checkbox matInput formControlName="unlimitedQuantity" color="primary">Unlimited Appointments</mat-checkbox>
        </div>

        <div class="flex-0 ml-2 mt-3">
            <mat-checkbox matInput formControlName="hsaApproved" color="primary">HSA Approved</mat-checkbox>
        </div>

        <div class="flex-0 ml-2 mt-3">
            <mat-checkbox matInput [checked]="isActive" (change)="toggleStatus()" color="primary">Service Plan Active</mat-checkbox>
        </div>

        <div>
            <button [disabled]="!this.servicePlanFormGroup.valid" class="ml-5 mt-2" mat-raised-button (click)="submitChanges()" color="primary">
        Save
      </button>
        </div>

        <div>
            <button class="ml-3 mt-2" mat-raised-button mat-dialog-close color="warn">
        Cancel
      </button>
        </div>
    </div>
</form>