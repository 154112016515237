<div class="flex-row margin-top-20">
    <div class="flex-1"></div>

    <div class="flex-3">
        <button mat-raised-button 
                color="primary"                 
                (click)="createServiceApplication()" 
                class="span-form margin-tb-10">
            <span style="vertical-align: bottom;">+&nbsp;&nbsp;</span> New Application
        </button>
    
        <div class="scrollable-table">
            <mat-progress-bar *ngIf="loading" style="height: 5px; width: 100%;" mode="indeterminate"></mat-progress-bar>

            <table mat-table [dataSource]="serviceApplications" class="mat-elevation-z8">
                <ng-container matColumnDef="name">            
                    <th mat-header-cell *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let element">{{element?.serviceProviderModel?.firstName + " " + element?.serviceProviderModel?.lastName}}</td>
                </ng-container>
        
                <ng-container matColumnDef="applicationStatus">            
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let element">{{element?.serviceApplicationStatus}}</td>
                </ng-container>
        
                <ng-container matColumnDef="dateCreated">            
                    <th mat-header-cell *matHeaderCellDef>Date Created</th>
                    <td mat-cell *matCellDef="let element">{{element?.createDateUTC | date:'shortDate'}}</td>
                </ng-container>
    
                <ng-container matColumnDef="action">            
                    <th mat-header-cell *matHeaderCellDef>
                        <button mat-icon-button 
                                [disabled]="loading" 
                                (click)="refreshTable()" 
                                class="span-form margin-tb-10">
                            <mat-icon>refresh</mat-icon>
                        </button>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button 
                                [disabled]="element.serviceApplicationStatus != 'Started'" 
                                matTooltip="Update application"
                                (click)="createServiceApplication(element.serviceApplicationId)" 
                                class="span-form margin-tb-10">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </td>
                </ng-container>
        
                <tr mat-header-row *matHeaderRowDef="columnHeaders"></tr>
                <tr mat-row *matRowDef="let row; columns: columnHeaders;"></tr>
    
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No applications found.</td>
                </tr>
            </table>
        </div>        
    </div>

    <div class="flex-1"></div>
</div>
