<section class="container mt-4">
    <h1 class="text-muted">Subscribers
        <mat-icon class="text-muted">subscriptions</mat-icon>
    </h1>

    <mat-divider class="mb-3"></mat-divider>

    <div class="flex-row">

        <mat-card *ngFor="let sub of subscriptions" class="card ml-2">
            <div class="edit-subs-btn" mat-card-avatar>
            </div>

            <mat-card-title class="text-muted">{{sub.userName}}</mat-card-title>

            <mat-card-subtitle class="warn-font" *ngIf="sub.subscriptionCancelAtPeriodEnd">Subscription Canceled</mat-card-subtitle>

            <mat-card-subtitle>Subscription paid until {{sub.subscriptionPaidTill | date: 'shortDate'}}</mat-card-subtitle>

            <mat-card-subtitle>{{sub.paymentOccurrence + 'ly'}} Subscription</mat-card-subtitle>

            <mat-divider class="mb-3" inset></mat-divider>
            <div class="card-data">
                <mat-card-content>
                    <p> Subscription purchased {{sub.subscriptionCreateDateUTC | date: 'shortDate'}}</p>
                    <p>{{sub.servicePlanDescription}}</p>
                    <p>{{sub.appointmentLocation}}</p>
                    <p>${{sub.servicePlanRate.toFixed(2)}}</p>
                </mat-card-content>
            </div>

            <mat-divider class="mb-3" inset></mat-divider>

            <mat-card-title class="text-muted">Contact Info</mat-card-title>

            <div>
                <mat-card-content>
                    <p>{{sub.userEmailAddress}}</p>
                    <p>{{sub.userPhoneNumber}}</p>
                </mat-card-content>
            </div>
        </mat-card>
    </div>
</section>