import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceApplicationServiceDocument } from '../models/service_application_service_document.model';
import { AbstractService } from './abstract_service.service';

@Injectable({
    providedIn: 'root'
})
export class ServiceApplicationServiceDocumentService extends AbstractService {
  path = 'ServiceApplicationServiceDocument';
}

