import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Material Components
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';

// Our components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { RegistrationConfirmationComponent } from './components/registration_confirmation/registration_confirmation.component';
import { AuthGuardService } from './services/auth_guard_service.service';
import { authInterceptorProviders } from './services/auth_intercepter.service';
import { errorInterceptorProviders } from './services/error_intercepter.service';
import { ForgotPasswordComponent } from './components/forgot_password/forgot_password.component';
import { ChangePasswordComponent } from './components/change_password/change_password.component';
import { ApplicationComponent } from './components/application/application.component';
import { UpdateApplicationComponent } from './components/update_application/update_application.component';
import { UpsertServicePlanDialog } from './components/update_application/dialogs/upsert_service_plan_dialog.component';
import { SuccessDialogComponent } from './components/success-dialog/success-dialog.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { SettingsComponent } from './components/settings/settings.component';
import {MatCardModule} from '@angular/material/card';
import { AchUpdateComponent } from './components/ach-update/ach-update.component';
import { ServiceManagementComponent } from './components/service-management/service-management.component';
import { ProfileManagementComponent } from './components/profile-management/profile-management.component';
import { DigitOnlyDirective } from './components/ach-update/only-number.directive';
import { EditServicePlanDialogComponent } from './components/service-management/edit-service-plan-dialog/edit-service-plan-dialog.component';
import { TwoDecimalDirectiveDirective } from './components/service-management/edit-service-plan-dialog/two-decimal.directive';
import { WholeNumberDirective } from './components/utils/whole-number-directive.directive';
import { AutocompleteOffDirective } from './components/ach-update/auto-complete-off.directive';
import { ConfirmServicePlanEditDialogComponent } from './components/service-management/confirm-service-plan-edit-dialog/confirm-service-plan-edit-dialog.component';
import { SubscriberManagementComponent } from './components/subscriber-management/subscriber-management.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { ProfilePageComponent } from './components/profile-page/profile-page.component';
import { UpdatePhotoDialogComponent } from './components/profile-page/update-photo-dialog/update-photo-dialog/update-photo-dialog.component';
import { SalesComponent } from './components/sales/sales.component';
import { StripeSetupComponent } from './components/stripe-setup/stripe-setup.component';
import { StripeSetupFinishComponent } from './components/stripe-setup-finish/stripe-setup-finish.component';



@NgModule({
  declarations: [
    AppComponent,
    ProfileManagementComponent,
    LoginComponent,
    NavbarComponent,
    RegistrationComponent,
    RegistrationConfirmationComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    ApplicationComponent,
    UpdateApplicationComponent,
    UpsertServicePlanDialog,
    SuccessDialogComponent,
    TermsAndConditionsComponent,
    SettingsComponent,
    AchUpdateComponent,
    ServiceManagementComponent,
    DigitOnlyDirective,
    EditServicePlanDialogComponent,
    TwoDecimalDirectiveDirective,
    WholeNumberDirective,
    AutocompleteOffDirective,
    ConfirmServicePlanEditDialogComponent,
    SubscriberManagementComponent,
    ProfilePageComponent,
    UpdatePhotoDialogComponent,
    SalesComponent,
    StripeSetupComponent,
    StripeSetupFinishComponent
    
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    MatButtonModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatInputModule,
    MatIconModule,
    MatListModule,
    MatNativeDateModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTableModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatCheckboxModule,
    PdfViewerModule,
    MatCardModule,
    MatSnackBarModule,
    MatPaginatorModule,
  ],
  providers: [
    authInterceptorProviders,
    errorInterceptorProviders,
    [AuthGuardService],
  ],
  exports: [
    DigitOnlyDirective,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
