<mat-toolbar class="navbar">
    <button *ngIf="isUserLoggedIn" mat-icon-button class="menu-button" (click)="toggleSidenav()"><mat-icon>menu</mat-icon></button>

    <a routerLink="/settings">
        <img src="../../../assets/images/Logo.png" class="logo ml-4">
    </a>
    <span class="example-spacer"></span>
    <span class="clickable" (click)="navigateToSettings()">Account</span>
    <mat-icon (click)="navigateToSettings()" class="clickable ml-2">account_circle</mat-icon>
</mat-toolbar>

<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #sidenav [mode]="'over'" [opened]="showSidenav">
        <mat-nav-list>
            <a mat-list-item href="{{upperNewsLink}}">About</a>
            <a mat-list-item routerLink="{{route.key}}" routerLinkActive="active-link" *ngFor="let route of routes | keyvalue">{{route.value}}</a>
        </mat-nav-list>

        <button mat-flat-button class="logout-button" (click)="logout()">Logout</button>
    </mat-sidenav>

    <mat-sidenav-content>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>