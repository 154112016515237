import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceDocument } from '../models/service_document.model';

@Injectable({
    providedIn: 'root'
})
export class ServiceDocumentService {

  get baseUrl(): string {
      if (window.location.href.toLowerCase().includes('https://upperproviderportal.z13.web.core.windows.net') || window.location.href.toLowerCase().includes('https://provider.upper.health')) {
          return 'https://upper-api.azurewebsites.net';
      } else if (window.location.href.includes('localhost')) {
          return 'https://localhost:5001';
      }
      return 'https://upper-qa-api.azurewebsites.net';
  }

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  path = 'ServiceDocument';
  serviceApplicationPath = 'ServiceApplication';

  // post the file as a form body with enctype header
  uploadFile(serviceDocument: File): Observable<any> {
    const formData = new FormData();
    formData.append('formFile', serviceDocument, serviceDocument.name);

    const options = {headers: new HttpHeaders({
        enctype: 'multipart/form-data',
        Accept: 'application/json'
    })};

    return this.http.post<string>(
        `${this.baseUrl}/${this.path}`,
        formData,
        options,
    );
  }

  download(serviceDocumentId: string): Observable<any> {
    return this.http.get<any>(
        `${this.baseUrl}/${this.path}/${serviceDocumentId}/Download`,
        this.httpOptions,
    );
  }

  getList(): Observable<ServiceDocument[]> {
    return this.http.get<ServiceDocument[]>(
        `${this.baseUrl}/${this.path}/All`,
        this.httpOptions,
    );
  }

  put(pathSegments: string[], body: any): Observable<any> {
    const pathSegmentString = this._buildPathSegments(pathSegments);

    return this.http.put<any>(
        `${this.baseUrl}/${this.path}${pathSegmentString}`,
        JSON.stringify(body),
        this.httpOptions,
    );
  }


  get(serviceDocumentId: string): Observable<ServiceDocument> {
    return this.http.get<ServiceDocument>(
        `${this.baseUrl}/${this.path}/${serviceDocumentId}`,
        this.httpOptions,
    );
  }

  private _buildPathSegments(pathSegments: string[]): string {
    return pathSegments != null && pathSegments.length > 0 ? `/${pathSegments}` : '';
  }
}
