import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ServiceProvider } from 'src/app/models/service_provider.model';
import { ServiceProviderService } from 'src/app/services/service-provider.service';
import { TokenStorageService } from 'src/app/services/token_storage.service';
@Component({
    selector: 'app-profile-management',
    templateUrl: './profile-management.component.html',
    styleUrls: ['./profile-management.component.scss'],
})
export class ProfileManagementComponent implements OnInit {
    serviceProvider!: ServiceProvider;
    loading: boolean = false;
    updateSuccess: boolean = false;

    states: string[] = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 
                      'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 
                      'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 
                      'LA', 'ME', 'MD', 'MA', 'MI', 'MN',
                      'MS', 'MO', 'MT', 'NE', 'NV', 'NH',
                      'NJ', 'NM', 'NY', 'NC', 'ND', 'OH',
                      'OK', 'OR', 'PA', 'RI', 'SC', 'SD',
                      'TN', 'TX', 'UT', 'VT', 'VA', 'WA',
                      'WV', 'WI', 'WY']

    providerFormGroup = new FormGroup({
        serviceProviderId: new FormControl(),
        firstName: new FormControl(Validators.required),
        lastName: new FormControl(Validators.required),
        primaryPhoneNumber: new FormControl([Validators.required, Validators.pattern(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)]),
        secondaryPhoneNumber: new FormControl([Validators.pattern(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)]),
        address: new FormControl(Validators.required),
        city: new FormControl(Validators.required),
        state: new FormControl(Validators.required),
        zipCode: new FormControl([Validators.required, Validators.pattern('(^\\d{5}$)|(^\\d{9}$)|(^\\d{5}-\\d{4}$)')]),
        website: new FormControl(),
        businessName: new FormControl(Validators.required),
        profileBio: new FormControl(),
        emailAddress: new FormControl(Validators.required),
    });

    constructor(
        private userService: TokenStorageService,
        private serviceProviderService: ServiceProviderService,
    ) {}

    ngOnInit(): void {
        const emptyQueryParams = new Map<string, any>();
        this.serviceProviderService.get([this.userService.getUser().id], emptyQueryParams).subscribe(result =>{
            this.serviceProvider = result;
            this.providerFormGroup.controls['serviceProviderId'].setValue(this.serviceProvider.serviceProviderId);
            this.providerFormGroup.controls['firstName'].setValue(this.serviceProvider.firstName);
            this.providerFormGroup.controls['lastName'].setValue(this.serviceProvider.lastName);
            this.providerFormGroup.controls['primaryPhoneNumber'].setValue(this.serviceProvider.primaryPhoneNumber);
            this.providerFormGroup.controls['secondaryPhoneNumber'].setValue(this.serviceProvider.secondaryPhoneNumber);
            this.providerFormGroup.controls['address'].setValue(this.serviceProvider.address);
            this.providerFormGroup.controls['city'].setValue(this.serviceProvider.city);
            this.providerFormGroup.controls['state'].setValue(this.serviceProvider.state);
            this.providerFormGroup.controls['zipCode'].setValue(this.serviceProvider.zipCode);
            this.providerFormGroup.controls['website'].setValue(this.serviceProvider.website);
            this.providerFormGroup.controls['profileBio'].setValue(this.serviceProvider.profileBio);
            this.providerFormGroup.controls['emailAddress'].setValue(this.serviceProvider.emailAddress);
            if(this.serviceProvider.businessName == null) {
                this.providerFormGroup.controls['businessName'].setValue('');

            } else {
                this.providerFormGroup.controls['businessName'].setValue(this.serviceProvider.businessName);
            }
        });
    }

    selectState(e: any): void {
        this.providerFormGroup.controls.state.setValue(e.target.value, {
          onlySelf: true
        }); 
      }

      saveProviderUpdates():void {
          this.loading = true;
        this.serviceProviderService.put([this.serviceProvider.serviceProviderId], this.providerFormGroup.getRawValue()).subscribe(() => {
            this.loading = false;
            this.updateSuccess = true;
        })
      }


}
