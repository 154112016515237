import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FileUpload } from 'src/app/models/file_upload.model';
import { ServiceApplication } from 'src/app/models/service_application.model';
import { ServiceApplicationServiceDocument } from 'src/app/models/service_application_service_document.model';
import { ServiceApplicationServicePlan } from 'src/app/models/service_application_service_plan.model';
import { ServiceDocument } from 'src/app/models/service_document.model';
import { ServicePlan } from 'src/app/models/service_plan.model';
import { ServiceType } from 'src/app/models/service_type.model';
import { ServiceApplicationService } from 'src/app/services/service_application_service';
import { ServiceApplicationServiceDocumentService } from 'src/app/services/service_application_service_document_service.service';
import { ServiceApplicationServicePlanService } from 'src/app/services/service_application_service_plan_service.service';
import { ServiceDocumentService } from 'src/app/services/service_document_service.service';
import { ServicePlanService } from 'src/app/services/service_plan.service';
import { ServiceTypeService } from 'src/app/services/service_type_service.service';
import { TokenStorageService } from 'src/app/services/token_storage.service';
import { UpsertServicePlanDialog } from './dialogs/upsert_service_plan_dialog.component';
import { FileCategory } from 'src/app/enums/file_category.enum';
import { ServiceProviderService } from 'src/app/services/service-provider.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
    selector: 'update-application',
    templateUrl: './update_application.component.html',
    styleUrls: ['./update_application.component.scss'],
})
export class UpdateApplicationComponent implements OnInit {

    @ViewChild('fileInput')
    fileInputVariable!: ElementRef;

    serviceApplication?: ServiceApplication;

    savingServiceApplication = false;
    loadingServiceDocuments = false;
    loadingServiceApplicationServiceDocuments = false;
    loadingServiceApplicationServicePlans = false;
    loadingServiceTypes = false;
    termsLink!: string;

    // documents
    selectedFile!: File;
    serviceDocuments: ServiceDocument[] = [];
    serviceApplicationServiceDocuments: ServiceApplicationServiceDocument[] = [];
    serviceDocumentDataSource = new MatTableDataSource<ServiceDocument>([]);
    serviceDocumentColumnHeaders: string[] = ['name', 'dateCreated', 'issuedDate', 'expirationDate', 'fileCategory', 'action'];

    //File Categories
    license = FileCategory.LicenseCert;
    id = FileCategory.Id;
    insurance = FileCategory.Insurance;

    nonAcceptedFileType: boolean = false;
    uploadServerError: boolean = false;
    uploadServerMessage: string = 'Upload Failed';

    // service types
    serviceTypeFormControl = new FormControl();
    rateTypeFormControl = new FormControl();
    serviceTypeFilter = '';
    serviceTypes: ServiceType[] = [];

    // service plans
    servicePlanDataSource = new MatTableDataSource<ServicePlan>([]);
    serviceApplicationServicePlanDataSource = new MatTableDataSource<ServiceApplicationServicePlan>([]);
    servicePlanColumnHeaders: string[] = ['type', 'action'];
    selectedServicePlanColumnHeaders: string[] = ['type', 'description', 'action'];
    selectedServicePlanCurrentPage: number = 0;
    selectedServicePlanTotalItemCount: number = 0;
    serviceApplicationServicePlanColumnHeaders: string[] = ['type', 'description', 'rate', 'rateType', 'action'];
    servicePlans: ServicePlan[] = [];
    serviceApplicationServicePlans: ServiceApplicationServicePlan[] = [];

    //Date Form Controls
    dateFormGroup = new FormGroup({

        idIssuedDate: new FormControl(null),
        idExpirationDate: new FormControl(null),

        certificateIssuedDate: new FormControl(null),
        certificateExpirationDate: new FormControl(null),

        insuranceIssuedDate: new FormControl(null),
        insuranceExpirationDate: new FormControl(null),

    });

    termsAndConditions = new FormGroup({
        agreeToTerms: new FormControl(false),
    });

    rateTypes = new Map([
        ['Hourly', 'Hourly'],
        ['Monthly', 'Monthly'],
    ]);

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private tokenStorageService: TokenStorageService,
        private serviceApplicationService: ServiceApplicationService,
        private serviceDocumentService: ServiceDocumentService,
        private serviceTypeService: ServiceTypeService,
        private serviceApplicationServiceDocumentService: ServiceApplicationServiceDocumentService,
        private servicePlanService: ServicePlanService,
        private serviceApplicationServicePlanService: ServiceApplicationServicePlanService,
        private serviceProviderService: ServiceProviderService,
        public dialog: MatDialog,
    ) {
        this.serviceTypeFormControl.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged(),
        ).subscribe(value => {
            this.serviceTypeFilter = value;
            this.getServiceTypes();
        });

        this.serviceProviderService.get(['terms'], new Map).subscribe((result) => {
            this.termsLink = result.link;
        });
    }

    ngOnInit(): void {
        this.activatedRoute.paramMap.subscribe(params => {
            const id = params.get('id')
            if (id != null) {
                // Get Application
                this.serviceApplicationService.get([id], new Map()).subscribe((serviceApplication: any) => {
                    this.serviceApplication = serviceApplication;

                    this.getServiceApplicationServiceDocuments();
                    this.getServiceApplicationServicePlans();
                });

                this.getServiceDocuments();
                this.getServiceTypes();
            } else {
                this.router.navigate(['application']);
            }
        });
    }

    getServiceDocuments(): void {
        this.loadingServiceDocuments = true;

        this.serviceDocumentService.getList().subscribe(x => {
            this.serviceDocuments = x;
            this.loadingServiceDocuments = false;
            this.refreshServiceDocumentPlanTable();
        }, (error) => this.loadingServiceDocuments = false);
    }

    getServiceTypes(): void {
        this.loadingServiceTypes = true;

        const map = new Map();
        map.set('name', this.serviceTypeFilter);

        this.serviceTypeService.getAll(['All'], map).subscribe(x => {
            this.serviceTypes = x;

            // Use ServicePlan object for the table so we can POST/DELETE ServicePlan(s) directly
            // This lets us do away with tethering form controls to every ServiceType row
            this.servicePlans = this.serviceTypes.map(serviceType => {
                const servicePlan = new ServicePlan();
                servicePlan.serviceTypeModel = serviceType;
                return servicePlan;
            });

            this.loadingServiceTypes = false;
            this.refreshServicePlanTable();
        }, (error) => this.loadingServiceTypes = false);
    }

    getServiceApplicationServiceDocuments(): void {
        if (this.serviceApplication != null) {
            this.loadingServiceApplicationServiceDocuments = true;

            const map = new Map();
            map.set('serviceApplicationId', this.serviceApplication.serviceApplicationId);

            this.serviceApplicationServiceDocumentService
                .getAll([], map)
                .subscribe(x => {
                    this.serviceApplicationServiceDocuments = x;
                    this.loadingServiceApplicationServiceDocuments = false;
                    this.refreshServiceDocumentPlanTable();
                }, _ => this.loadingServiceApplicationServiceDocuments = false);
        }
    }

    getServiceApplicationServicePlans(): void {
        if (this.serviceApplication != null) {
            this.loadingServiceApplicationServicePlans = true;

            const map = new Map();
            map.set('serviceApplicationId', this.serviceApplication.serviceApplicationId);
            map.set('pageNumber', this.selectedServicePlanCurrentPage + 1);

            this.serviceApplicationServicePlanService
                .getAllPaged([], map)
                .subscribe((x: any) => {
                    let paginationData = JSON.parse(x.headers.get('x-pagination'));
                    this.selectedServicePlanTotalItemCount = paginationData.totalCount;

                    this.serviceApplicationServicePlans = x.body;
                    this.loadingServiceApplicationServicePlans = false;
                    this.refreshServiceApplicationServicePlanTable();
                }, _ => this.loadingServiceApplicationServicePlans = false);
        }
    }

    changePage(event: PageEvent): void {
        this.selectedServicePlanCurrentPage = event.pageIndex
        this.getServiceApplicationServicePlans();
    }

    selectFile(event: any, fileCategory: string): void {
        this.nonAcceptedFileType = false;
        this.uploadServerError = false;
        var file = event.target.files[0];;
        let isAllowedFileExt: boolean = false;
        const ext = file.name.substring(file.name.lastIndexOf('.')+1, file.name.length).toLocaleLowerCase() || undefined;

        switch (ext) {
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'doc':
            case 'docx':
            case 'pdf':
                isAllowedFileExt = true;
                break;
            default:
                alert('Non-Accepted File Type');
        }

        if (isAllowedFileExt) {
            this.selectedFile = event.target.files[0];
            this.uploadFile(fileCategory);
        } else {
            this.nonAcceptedFileType = true;
        }

    }

    uploadFile(fileCategory: string): void {
        this.loadingServiceDocuments = true;
        this.serviceDocumentService.uploadFile(this.selectedFile).subscribe(x => {
            this.updateFileDates(x.serviceDocumentId, fileCategory);
            this.getServiceApplicationServiceDocuments();
            this.createServiceApplicationServiceDocument(x.serviceDocumentId);
            this.loadingServiceDocuments = false;
        }, (error) => {
            this.uploadServerError = true;
            this.uploadServerMessage = error;
            this.getServiceDocuments();
            this.getServiceApplicationServiceDocuments();
            this.loadingServiceDocuments = false;
        });
    }

    updateFileDates(fileId: any, fileCategory: string): void {
        const fileData: FileUpload = new FileUpload();
        fileData.fileCategory = fileCategory;
        switch (fileCategory) {
            case this.id:
                fileData.expirationDate = this.dateFormGroup.controls.idExpirationDate.value;
                fileData.issuedDate = this.dateFormGroup.controls.idIssuedDate.value;
                break;
            case this.insurance:
                fileData.expirationDate = this.dateFormGroup.controls.insuranceExpirationDate.value;
                fileData.issuedDate = this.dateFormGroup.controls.insuranceIssuedDate.value;
                break;
            case this.license:
                fileData.expirationDate = this.dateFormGroup.controls.certificateExpirationDate.value;
                fileData.issuedDate = this.dateFormGroup.controls.certificateIssuedDate.value;
                break;
            default:
                fileData.expirationDate = new Date();
                fileData.issuedDate = new Date();
                break;
        }

        this.serviceDocumentService.put(fileId, fileData).subscribe(() => {
            this.getServiceDocuments();
        })
    }

    createServiceApplicationServicePlanDialog(servicePlan: ServicePlan): void {
        // Default to Hourly
        servicePlan.description = '';
        servicePlan.rate = 0;
        servicePlan.quantityAvailable  = 0;
        servicePlan.inOfficeAvailability = false;
        servicePlan.virtualAvailability = false;
        servicePlan.homeCareAvailability = false;
        servicePlan.appointmentType = 'Hourly';
        servicePlan.unlimitedQuantity = false;
        servicePlan.hsaApproved = false;

        const dialogRef = this.dialog.open(UpsertServicePlanDialog, {
            width: '800px',
            data: servicePlan
        });

        dialogRef
            .afterClosed()
            .subscribe(result => {
                console.log(result);
                if (result) {
                    this.createServiceApplicationServicePlan(result);
                }
            });
    }

    createServiceApplicationServicePlan(servicePlan: ServicePlan): void {
        const description = servicePlan.description;
        const rate = servicePlan.rate;
        const appointmentType = servicePlan.appointmentType;
        const quantityAvailable = servicePlan.quantityAvailable;
        const unlimitedQuantity = servicePlan.unlimitedQuantity;
        const inOfficeAvailability = servicePlan.inOfficeAvailability;
        const virtualAvailability = servicePlan.virtualAvailability;
        const homeCareAvailability = servicePlan.homeCareAvailability;
        const serviceTypeId = servicePlan.serviceTypeModel.serviceTypeId;
        const serviceProviderId = this.tokenStorageService.getUser().id;
        const paymentOccurrence = servicePlan.paymentOccurrence;
        const hsaApproved = servicePlan.hsaApproved;

        this.servicePlanService
            .post([], { description, rate, appointmentType, serviceTypeId, serviceProviderId,paymentOccurrence, quantityAvailable, unlimitedQuantity, inOfficeAvailability, virtualAvailability, homeCareAvailability, hsaApproved })
            .subscribe(x => {
                if (this.serviceApplication != null) {
                    const serviceApplicationId = this.serviceApplication.serviceApplicationId;
                    const servicePlanId = x.servicePlanId;

                    this.serviceApplicationServicePlanService
                        .post([], { serviceApplicationId, servicePlanId })
                        .subscribe(_ => {
                            this.getServiceApplicationServicePlans();
                            this.refreshServicePlanTable();
                        });
                }
            }, _ => this.refreshServicePlanTable());
    }

    createServiceApplicationServiceDocument(serviceDocumentId: string): void {
        if (this.serviceApplication != null) {

            const serviceApplicationId = this.serviceApplication.serviceApplicationId;

            this.serviceApplicationServiceDocumentService
                .post([], { serviceApplicationId, serviceDocumentId })
                .subscribe(_ => this.getServiceApplicationServiceDocuments());
        }
    }

    editServiceApplicationServicePlanDialog(serviceApplicationServicePlan: ServiceApplicationServicePlan): void {
        const dialogRef = this.dialog.open(UpsertServicePlanDialog, {
            width: '800px',
            data: serviceApplicationServicePlan.servicePlanModel
        });

        dialogRef
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    this.editServiceApplicationServicePlan(result);
                }
            });
    }

    editServiceApplicationServicePlan(servicePlan: ServicePlan): void {
        const servicePlanId = servicePlan.servicePlanId;
        const description = servicePlan.description;
        const rate = servicePlan.rate;
        const appointmentType = servicePlan.appointmentType;
        const quantityAvailable = servicePlan.quantityAvailable;
        const unlimitedQuantity = servicePlan.unlimitedQuantity;
        const inOfficeAvailability = servicePlan.inOfficeAvailability;
        const virtualAvailability = servicePlan.virtualAvailability;
        const homeCareAvailability = servicePlan.homeCareAvailability;
        const serviceTypeId = servicePlan.serviceTypeModel.serviceTypeId;
        const serviceProviderId = this.tokenStorageService.getUser().id;
        const hsaApproved = servicePlan.hsaApproved;

        this.servicePlanService
            .put([servicePlanId], { servicePlanId, description, rate, appointmentType, quantityAvailable, unlimitedQuantity, serviceTypeId, serviceProviderId, inOfficeAvailability, virtualAvailability, homeCareAvailability, hsaApproved })
            .subscribe(_ => {
                this.getServiceApplicationServicePlans();
                this.refreshServicePlanTable();
            }, _ => this.refreshServicePlanTable());
    }

    deleteServiceApplicationServicePlan(serviceApplicationServicePlan: ServiceApplicationServicePlan): void {
        this.loadingServiceApplicationServicePlans = true;

        this.serviceApplicationServicePlanService
            .delete(serviceApplicationServicePlan.serviceApplicationServicePlanId)
            .subscribe(x => {
                this.loadingServiceApplicationServicePlans = false;
                this.getServiceApplicationServicePlans();
            }, _ => this.loadingServiceApplicationServicePlans = false);
    }

    deleteServiceApplicationServiceDocument(serviceDocumentId: string): void {
        const serviceApplicationServiceDocument = this.serviceApplicationServiceDocuments.filter(x => x.serviceDocumentId === serviceDocumentId)[0];

        this.loadingServiceApplicationServiceDocuments = true;

        this.serviceApplicationServiceDocumentService
            .delete(serviceApplicationServiceDocument.serviceApplicationServiceDocumentId)
            .subscribe(x => {
                this.loadingServiceApplicationServiceDocuments = false;
                this.getServiceApplicationServiceDocuments();
            }, _ => this.loadingServiceApplicationServiceDocuments = false);
    }

    clearServiceTypeFilter(): void {
        this.serviceTypeFilter = '';
    }

    refreshServicePlanTable(): void {
        this.servicePlanDataSource.data = this.servicePlans;
    }

    refreshServiceApplicationServicePlanTable(): void {
        this.serviceApplicationServicePlanDataSource.data = this.serviceApplicationServicePlans;
    }

    refreshServiceDocumentPlanTable(): void {
        this.serviceDocumentDataSource.data = this.serviceDocuments;
    }

    onSubmitApplication(): void {
        if (this.serviceApplication != null) {
            this.savingServiceApplication = true;
            const serviceApplicationId = this.serviceApplication.serviceApplicationId;

            this.serviceApplicationService
                .put([], { serviceApplicationId })
                .subscribe(_ => {
                    this.savingServiceApplication = false;
                    this.router.navigate(['settings']);
                }, _ => {
                    this.savingServiceApplication = false;
                });
        }
    }

    isServiceDocumentLinked(serviceDocumentId: string): boolean {
        return this.serviceApplicationServiceDocuments.filter(x => x.serviceDocumentId === serviceDocumentId).length > 0;
    }

    viewFile(serviceDocument: ServiceDocument): void {
        if (serviceDocument?.serviceDocumentId != null) {
            this.serviceDocumentService.download(serviceDocument.serviceDocumentId).subscribe((x: any) => {
                const binaryString = window.atob(x.data);
                const len = binaryString.length;
                const bytes = new Uint8Array(len);

                for (let i = 0; i < len; i++) {
                    bytes[i] = binaryString.charCodeAt(i);
                }

                const a = window.document.createElement('a');
                const fileURl = a.href = window.URL.createObjectURL(new Blob([bytes], { type: serviceDocument.contentType }));
                window.open(fileURl);
            });
        }
    }
}
