import { Directive, ElementRef, HostListener } from '@angular/core';
@Directive({
  selector: '[appWholeNumberDirective]'
})
export class WholeNumberDirective {

  constructor(private element: ElementRef) {}

  @HostListener('change') change() {
      let num: number = Number(this.element.nativeElement.value);
      this.element.nativeElement.value = num.toFixed(0);
  }
}
