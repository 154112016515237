import { Component } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ForgotPasswordService } from 'src/app/services/forgot_password_service.service';

@Component({
    selector: 'forgot-password',
    templateUrl: './forgot_password.component.html',
    styleUrls: ['./forgot_password.component.scss']
})
export class ForgotPasswordComponent {

    forgotPasswordForm = new FormGroup({
        emailAddress: new FormControl('', [Validators.required, Validators.email]),
    });

    loading = false;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private forgotPasswordService: ForgotPasswordService,
    ) {  }

    get formControls(): { [p: string]: AbstractControl } {
        return this.forgotPasswordForm.controls;
    }


    onSubmit(): void {
        const emailAddress = this.formControls.emailAddress.value;
        this.loading = true;
        this.forgotPasswordService
            .post([], { emailAddress })
            .subscribe(
                _ => {
                    this.loading = false;
                    this.router.navigate(['login']);
                },
                _ => {
                    this.loading = false;
                    this.router.navigate(['login']);
                });

    }
}
