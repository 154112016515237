import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceProviderService } from 'src/app/services/service-provider.service';
import { TokenStorageService } from 'src/app/services/token_storage.service';

@Component({
  selector: 'app-stripe-setup-finish',
  templateUrl: './stripe-setup-finish.component.html',
  styleUrls: ['./stripe-setup-finish.component.scss']
})
export class StripeSetupFinishComponent implements OnInit {
  userId: string | null = null;

  constructor(private tokenService: TokenStorageService, private router: Router, private serviceProviderService: ServiceProviderService) { }

  ngOnInit(): void {
    this.userId = this.tokenService.getUser().id;
  }

  completeExpressSetup(): void {
    this.serviceProviderService.post([<string>this.userId, 'complete-connected-account'], {}).subscribe(() => {
      this.router.navigate(['settings']);
    })
  }
}
