<h1 mat-dialog-title>Upload New Photo</h1>

<div mat-dialog-content>
    <div *ngIf="!loading" class="file-upload-container flex-1 mb-3">
        <input #fileInput id="file" type="file" accept=".png,.jpg,.jpeg" (change)="selectFile($event)" />
    </div>

    <div *ngIf="loading">
        <mat-spinner class="spinner" [diameter]="50"></mat-spinner>
    </div>

    <div *ngIf="errorMessage != undefined">
        <p class="error">{{errorMessage}}</p>
    </div>
</div>

<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="closeDialog()">Cancel</button>
</div>