import { Directive, ElementRef, HostListener } from '@angular/core';
@Directive({
  selector: '[appTwoDecimalDirective]'
})
export class TwoDecimalDirectiveDirective {

  constructor(private element: ElementRef) {}

  @HostListener('change') change() {
      let num: number = Number(this.element.nativeElement.value);
      this.element.nativeElement.value = num.toFixed(2);
  }
}

