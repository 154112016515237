import { ServiceProvider } from './service_provider.model';
import { ServiceType } from './service_type.model';

interface IServicePlan {
    servicePlanId: string;
    description: string;
    createDateUTC: Date;
    updateDateUTC: Date;
    serviceTypeModel: ServiceType;
    serviceTypeId: string;
    serviceProviderId: string;
    serviceProviderModel: ServiceProvider;
    rate: number;
    appointmentType: string;
    quantityAvailable: number;
    unlimitedQuantity: boolean;
    servicePlanStatus: string;
    inOfficeAvailability: boolean;
    virtualAvailability: boolean;
    homeCareAvailability: boolean;
    paymentOccurrence: string;
    hsaApproved: boolean;
}

export class ServicePlan {
    servicePlanId: string;
    description: string;
    createDateUTC: Date;
    updateDateUTC: Date;
    serviceTypeModel: ServiceType;
    serviceTypeId: string;
    serviceProviderId: string;
    serviceProviderModel: ServiceProvider;
    rate: number;
    appointmentType: string;
    quantityAvailable: number;
    unlimitedQuantity: boolean;
    servicePlanStatus: string;
    inOfficeAvailability: boolean;
    virtualAvailability: boolean;
    homeCareAvailability: boolean;
    paymentOccurrence: string;
    hsaApproved: boolean;

    constructor();
    constructor(object: IServicePlan);
    constructor(object?: any)  {
        this.servicePlanId = object && object.id || null;
        this.description = object && object.description || null;
        this.createDateUTC = object && object.createDateTime || null;
        this.updateDateUTC = object && object.updateDateTime || null;
        this.serviceTypeModel = object && object.serviceTypeModel || null;
        this.serviceProviderModel = object && object.serviceProviderModel || null;
        this.rate = object && object.rate || null;
        this.appointmentType = object && object.appointmentType || null;
        this.quantityAvailable = object && object.quantityAvailable || null;
        this.servicePlanStatus = object && object.servicePlanStatus || null;
        this.inOfficeAvailability = object && object.inOfficeAvailability || null;
        this.virtualAvailability = object && object.virtualAvailability || null;
        this.homeCareAvailability = object && object.homeCareAvailability || null;
        this.unlimitedQuantity = object && object.unlimitedQuantity || null;
        this.serviceTypeId = object && object.serviceTypeId || null;
        this.serviceProviderId = object && object.serviceProviderId || null;
        this.paymentOccurrence = object && object.paymentOccurrence || null;
        this.hsaApproved = object && object.hsaApproved || null;
    }
}
