<div class="flex-row padding-top">
    <div class="flex-1"></div>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="form-content flex-1">
        <h1 class="center-text">Provider Portal</h1>
        <h2 class="center-text">Login</h2>

        <mat-form-field>
            <mat-label>Email Address</mat-label>
            <input type="text" matInput formControlName="emailAddress">
            <mat-error *ngIf="formControls.emailAddress.hasError('required')">Email is required.</mat-error>
            <mat-error *ngIf="formControls.emailAddress.hasError('email')">Email is invalid.</mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Password</mat-label>
            <input type="password" matInput formControlName="password">
            <mat-error *ngIf="formControls.password.hasError('required')">Password is required.</mat-error>
        </mat-form-field>

        <div *ngIf="isLoginFailed" class="red-text error-padding">
            {{errorMessage}}
        </div>

        <div class="form-group">
            <button mat-raised-button color="primary" *ngIf="!loading" type="submit" class="btn btn-primary span-form" [disabled]="loading || !loginForm.valid">
            Login
          </button>

            <mat-progress-bar *ngIf="loading" style="height: 8px;" mode="indeterminate"></mat-progress-bar>

            <a mat-raised-button color="primary" routerLink="/forgot-password" routerLinkActive="forgot-password" class="btn btn-link span-form button-style">Forgot Password</a>
            <a mat-raised-button color="primary" routerLink="/registration" routerLinkActive="register" class="btn btn-link span-form button-style">Register</a>
        </div>
    </form>
    <div class="flex-1"></div>
</div>