import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from './abstract_service.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceProviderService extends AbstractService {
  path = 'ServiceProvider';

  constructor(protected http: HttpClient){
    super(http);
  }

  uploadFile(profileImage: File, serviceProviderId: string): Observable<any> {
    const formData = new FormData();
    formData.append('profileImg', profileImage, profileImage.name);

    const options = {headers: new HttpHeaders({
        enctype: 'multipart/form-data',
        Accept: 'application/json'
    })};

    return this.http.post<string>(
        `${this.baseUrl}/${this.path}/${serviceProviderId}/ProfileImage`,
        formData,
        options,
    );
  }

  getPayoutsEnabled(): Observable<boolean> {
    return this.http.get<boolean>(
      `${this.baseUrl}/${this.path}/payouts-enabled`
    );
  }
}
