<div class="content">
    <div>
        <h1 class="mt-3 title-font">Your Account</h1>
    </div>

    <div class="flex-row">
        <div (click)="navigateToUpdateACH()" class="flex-0 clickable card">
            <mat-card>
                <mat-card-header>
                    <mat-icon mat-card-avatar>payments</mat-icon>
                    <mat-card-title>Payment Information</mat-card-title>
                    <mat-card-subtitle>Update your payment info</mat-card-subtitle>
                </mat-card-header>
            </mat-card>
        </div>

        <div (click)="navigateToServiceManagement()" class="flex-0 clickable card">
            <mat-card>
                <mat-card-header>
                    <mat-icon mat-card-avatar>spa</mat-icon>
                    <mat-card-title>Your Service's</mat-card-title>
                    <mat-card-subtitle>Manage your services</mat-card-subtitle>
                </mat-card-header>
            </mat-card>
        </div>

        <div (click)="navigateToApplications()" class="flex-0 clickable card">
            <mat-card>
                <mat-card-header>
                    <mat-icon mat-card-avatar>approval</mat-icon>
                    <mat-card-title>Your Application's</mat-card-title>
                    <mat-card-subtitle>Manage applications</mat-card-subtitle>
                </mat-card-header>
            </mat-card>
        </div>

        <div (click)="navigateToProfileManagement()" class="flex-0 clickable card">
            <mat-card>
                <mat-card-header>
                    <mat-icon mat-card-avatar>description</mat-icon>
                    <mat-card-title>User Data</mat-card-title>
                    <mat-card-subtitle>Manage Your User Data</mat-card-subtitle>
                </mat-card-header>
            </mat-card>
        </div>

        <div (click)="navigateToSubscribers()" class="flex-0 clickable card">
            <mat-card>
                <mat-card-header>
                    <mat-icon mat-card-avatar>subscriptions</mat-icon>
                    <mat-card-title>Your Subscribers</mat-card-title>
                    <mat-card-subtitle>View Subscriptions</mat-card-subtitle>
                </mat-card-header>
            </mat-card>
        </div>

        <div (click)="navigateToProfile()" class="flex-0 clickable card">
            <mat-card>
                <mat-card-header>
                    <mat-icon mat-card-avatar>person</mat-icon>
                    <mat-card-title>Profile Page</mat-card-title>
                    <mat-card-subtitle>View/Edit Profile Page</mat-card-subtitle>
                </mat-card-header>
            </mat-card>
        </div>

        <div (click)="navigateToSales()" class="flex-0 clickable card">
            <mat-card>
                <mat-card-header>
                    <mat-icon mat-card-avatar>sell</mat-icon>
                    <mat-card-title>Sales Records</mat-card-title>
                    <mat-card-subtitle>View your sales</mat-card-subtitle>
                </mat-card-header>
            </mat-card>
        </div>

    </div>
</div>