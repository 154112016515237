interface IFileUpload {
    file: File;
    fileName: string;
    issuedDate: Date;
    expirationDate: Date;
    fileCategory: string;
}

export class FileUpload {
    public file: File;
    public fileName: string;
    public issuedDate: Date;
    public expirationDate: Date;
    public fileCategory: string;

    constructor();
    constructor(object: IFileUpload)
    constructor(object?: any) {
        this.file = object && object.file || null;
        this.issuedDate = object && object.issuedDate || null;
        this.expirationDate = object && object.expirationDate || null;
        this.fileCategory = object && object.fileCategory || "unknown";
        this.fileName = object && object.fileName || null;
    }
}