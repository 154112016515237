import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ServicePlan } from 'src/app/models/service_plan.model';
import { ServicePlanService } from 'src/app/services/service_plan.service';
import { TokenStorageService } from 'src/app/services/token_storage.service';
import { EditServicePlanDialogComponent } from './edit-service-plan-dialog/edit-service-plan-dialog.component';

@Component({
    selector: 'app-service-management',
    templateUrl: './service-management.component.html',
    styleUrls: ['./service-management.component.scss']
})
export class ServiceManagementComponent implements OnInit {
    loading = false;
    servicePlans: ServicePlan[] = [];
    displayedColumns: string[] = ['Service Type', 'Service Description','Active', 'Quantity Available', 'Unlimited Quantity', 'Rate', 'Appointment Type', 'Edit',];

    constructor(
        private servicePlanService: ServicePlanService,
        private userService: TokenStorageService,
        public dialog: MatDialog
    ) { }

    ngOnInit() {
        this.getServicePlans();
    }

    getServicePlans(): void {
        this.loading = true;
        const queryParams = new Map([
            ["serviceProviderId", this.userService.getUser().id],
        ]);
        this.servicePlanService.getAll([], queryParams).subscribe((result) => {
            this.servicePlans = result;
            this.loading = false;
        }, () => this.loading = false)
    }

    openEditServicePlanDialog(servicePlan: ServicePlan): void {
        const dialogRef = this.dialog.open(EditServicePlanDialogComponent, {
            data: servicePlan,
            width: '70%'
        });

        dialogRef.afterClosed().subscribe(() => {
            this.getServicePlans();
        });
    }
}
