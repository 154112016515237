import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServicePlan } from 'src/app/models/service_plan.model';
import { ServicePlanService } from 'src/app/services/service_plan.service';
import { ConfirmServicePlanEditDialogComponent } from '../confirm-service-plan-edit-dialog/confirm-service-plan-edit-dialog.component';

@Component({
    selector: 'app-edit-service-plan-dialog',
    templateUrl: './edit-service-plan-dialog.component.html',
    styleUrls: ['./edit-service-plan-dialog.component.scss']
})
export class EditServicePlanDialogComponent implements OnInit {
    servicePlan: ServicePlan = new ServicePlan();
    isActive: boolean = false;


    appointmentTypes: string[] = ['Hourly', '1/2 Hour', '1/4 Hour', '3/4 Hour', 'Package']

    servicePlanFormGroup: FormGroup = new FormGroup({
        appointmentType: new FormControl('', Validators.required),
        description: new FormControl('', [Validators.required, Validators.maxLength(120)]),
        homeCareAvailability: new FormControl(false),
        inOfficeAvailability: new FormControl(false),
        virtualAvailability: new FormControl(false),
        unlimitedQuantity: new FormControl(false),
        hsaApproved: new FormControl(),
        quantityAvailable: new FormControl(0, Validators.max(10000)),
        rate: new FormControl(0, [Validators.required,Validators.max(10000)]),
        serviceTypeId: new FormControl(),
        serviceProviderId: new FormControl(),
        servicePlanId: new FormControl(),
        servicePlanStatus: new FormControl(),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ServicePlan,
        public dialogRef: MatDialogRef<EditServicePlanDialogComponent>,
        private servicePlanService: ServicePlanService,
        public dialog: MatDialog,
    ) { }

    ngOnInit() {
        this.servicePlan = this.data;
        this.buildForm(this.servicePlan);
    }

    buildForm(servicePlan: ServicePlan): void {
        this.servicePlanFormGroup.controls.appointmentType.setValue(servicePlan.appointmentType);
        this.servicePlanFormGroup.controls.description.setValue(servicePlan.description);
        this.servicePlanFormGroup.controls.homeCareAvailability.setValue(servicePlan.homeCareAvailability);
        this.servicePlanFormGroup.controls.inOfficeAvailability.setValue(servicePlan.inOfficeAvailability);
        this.servicePlanFormGroup.controls.virtualAvailability.setValue(servicePlan.virtualAvailability);
        this.servicePlanFormGroup.controls.unlimitedQuantity.setValue(servicePlan.unlimitedQuantity);
        this.servicePlanFormGroup.controls.hsaApproved.setValue(servicePlan.hsaApproved);
        this.servicePlanFormGroup.controls.quantityAvailable.setValue(servicePlan.quantityAvailable);
        this.servicePlanFormGroup.controls.rate.setValue(servicePlan.rate.toFixed(2));
        this.servicePlanFormGroup.controls.serviceTypeId.setValue(servicePlan.serviceTypeId);
        this.servicePlanFormGroup.controls.serviceProviderId.setValue(servicePlan.serviceProviderId);
        this.servicePlanFormGroup.controls.servicePlanId.setValue(servicePlan.servicePlanId);

        if (servicePlan.servicePlanStatus.toLocaleLowerCase() === 'active') {
            this.servicePlanFormGroup.controls.servicePlanStatus.setValue('Active');
            this.isActive = true;
        } else {
            this.servicePlanFormGroup.controls.servicePlanStatus.setValue('Inactive');
            this.isActive = false;
        }
    }

    selectAppointmentType(e: any): void {
        this.servicePlanFormGroup.controls.appointmentType.setValue(e.value, {
            onlySelf: true
        });
    }

    toggleStatus(): void {
       
        this.isActive = !this.isActive;
        if (this.isActive) {
            this.servicePlanFormGroup.controls.servicePlanStatus.setValue('Active');
        } else {
            this.servicePlanFormGroup.controls.servicePlanStatus.setValue('Inactive');
        }
    }

    submitChanges(): void {

        const dialogRef = this.dialog.open(ConfirmServicePlanEditDialogComponent, {
        });

        dialogRef
        .afterClosed()
        .subscribe(agreed => {
            if (agreed) {
                this.servicePlanService.put([this.servicePlan.servicePlanId], this.servicePlanFormGroup.getRawValue()).subscribe(() => {
                    this.dialogRef.close();
                });
            } else {
                this.dialogRef.close();
            }
        });

        
    }
}
