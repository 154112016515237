<div class="flex-row padding-top">
    <div class="flex-1"></div>
    <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()" class="form-content flex-1">
        <h2 class="center-text">Forgot Password</h2>

      <mat-form-field>
        <mat-label>Email Address</mat-label>
        <input type="text" matInput formControlName="emailAddress">
        <mat-error *ngIf="formControls.emailAddress.hasError('required')">Email is required.</mat-error>
        <mat-error *ngIf="formControls.emailAddress.hasError('email')">Email is invalid.</mat-error>
      </mat-form-field>

        <div class="form-group">
          <button mat-raised-button color="primary" *ngIf="!loading" [disabled]="loading || !forgotPasswordForm.valid" class="btn btn-primary span-form">
            Send Email
          </button>
                <div class="flex-row">
                    <div class="flex-1"></div>
                    <mat-spinner *ngIf="loading"></mat-spinner>
                    <div class="flex-1"></div>
                </div>
        </div>
    </form>
    <div class="flex-1"></div>
</div>
