import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export abstract class AbstractService {
    get baseUrl(): string {
        if (window.location.href.toLowerCase().includes('https://upperproviderportal.z13.web.core.windows.net') || window.location.href.toLowerCase().includes('https://provider.upper.health')) {
            return 'https://upper-api.azurewebsites.net';
        } else if (window.location.href.includes('localhost')) {
            return 'https://localhost:5001';
        }
        return 'https://upper-qa-api.azurewebsites.net';
    }

    path !: string;

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        })
    };

    httpGetPagedOptions = {
        header: new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*', 
            'Access-Control-Expose-Headers': ['x-pagination'],
        })
    }

    constructor(
        protected http: HttpClient,
    ) {}

    post(pathSegments: string[], body: any): Observable<any> {
        const pathSegmentString = this._buildPathSegments(pathSegments);

        return this.http.post<any>(
            `${this.baseUrl}/${this.path}${pathSegmentString}`,
            JSON.stringify(body),
            this.httpOptions,
        );
    }

    put(pathSegments: string[], body: any): Observable<any> {
        const pathSegmentString = this._buildPathSegments(pathSegments);

        return this.http.put<any>(
            `${this.baseUrl}/${this.path}${pathSegmentString}`,
            JSON.stringify(body),
            this.httpOptions,
        );
    }

    getAll(pathSegments: string[], queryParameters: Map<string, any>): Observable<any[]> {
        const pathSegmentString = this._buildPathSegments(pathSegments);
        const queryParameterString = this._buildQueryParameters(queryParameters);

        return this.http.get<any[]>(
            `${this.baseUrl}/${this.path}${pathSegmentString}${queryParameterString}`,
            this.httpOptions,
        );
    }

    getAllPaged(pathSegments: string[], queryParameters: Map<string, any>): Observable<any> {
        const pathSegmentString = this._buildPathSegments(pathSegments);
        const queryParameterString = this._buildQueryParameters(queryParameters);

        return this.http.get<any[]>(
            `${this.baseUrl}/${this.path}${pathSegmentString}${queryParameterString}`,
            {
                ...this.httpOptions,
                observe: 'response'
            }
        );
    }

    get(pathSegments: string[], queryParameters: Map<string, any>): Observable<any> {
        const pathSegmentString = this._buildPathSegments(pathSegments);
        const queryParameterString = this._buildQueryParameters(queryParameters);

        return this.http.get<any>(
            `${this.baseUrl}/${this.path}${pathSegmentString}${queryParameterString}`,
            this.httpOptions,
        );
    }

    delete(applicationServiceTypeId: string): Observable<any> {
        return this.http.delete<any>(
            `${this.baseUrl}/${this.path}/${applicationServiceTypeId}`,
            this.httpOptions,
        );
    }

    private _buildPathSegments(pathSegments: string[]): string {
        return pathSegments != null && pathSegments.length > 0 ? `/${pathSegments.join('/')}` : '';
    }

    private _buildQueryParameters(queryParameters: Map<string, any>): string {
        return queryParameters != null && queryParameters.size > 0
            ? `?${Array.from(queryParameters.keys()).map(key => key + '=' + queryParameters.get(key)).join('&')}`
            : '';
    }
}
