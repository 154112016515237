import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServicePlan } from 'src/app/models/service_plan.model';

@Component({
    selector: 'upsert-service-plan-dialog',
    templateUrl: 'upsert_service_plan_dialog.component.html',
    styleUrls: ['upsert_service_plan_dialog.component.scss']
})
export class UpsertServicePlanDialog implements OnInit {


    constructor(
        public dialogRef: MatDialogRef<UpsertServicePlanDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ServicePlan) { }

    ngOnInit(): void {
        if (!this.data.quantityAvailable) {
            this.data.quantityAvailable = 0;
        }
        this.data.paymentOccurrence = 'One Time';
    }

    get isDisabled(): boolean {

        if (!this.data.description) {
            return true;
        } else if (this?.data?.rate == null) {
            return true;
        } else if (!this.data.appointmentType) {
            return true;
        } else if (!this.data.quantityAvailable && this.data.unlimitedQuantity == false) {
            return true;
        } else if (this.data.quantityAvailable != null && this.data.quantityAvailable < 0) {
            return true;
        } else if (this.data.rate != null && this.data.rate <= 0) {
            return true;
        }
        return false;
    }

    get setQuantityOrUnlimited(): boolean {

        if (!this.data.quantityAvailable && this.data.unlimitedQuantity == false) {
            return true;
        }
        return false;
    }

    get checkQuantityMax(): boolean {
        if (this.data.quantityAvailable > 1000) {
            return true;
        } return false;
    }

    get quantitySetWithUnlimitedChecked(): boolean {

        if (this.data.quantityAvailable != null && this.data.unlimitedQuantity == true) {
            return true;
        }
        return false;
    }

    get checkForNegativeQuantity(): boolean {

        if (this.data.unlimitedQuantity != true && this.data.quantityAvailable <= 0) {
            return true;
        }
        return false;
    }

    get checkForToLargeCost(): boolean {

        if (this.data.rate > 1000000) {
            return true;
        }
        return false;
    }

    get checkForNegativeRate(): boolean {

        if (this.data.rate != null && this.data.rate <= 0) {
            return true;
        }
        return false;
    }

    rateTypes = new Map([
        ['Hourly', 'Hourly'],
        ['1/2 Hour', '1/2 Hour'],
        ['1/4 Hour', '1/4 Hour'],
        ['3/4 Hour', '3/4 Hour'],
        ['Package', 'Package'],
        ['Subscription', 'Subscription']
    ]);

    paymentOccurrenceTypes = new Map([
        ['Month', 'Month'],
        ['Year', 'Year'],
    ]);

    onCancelClick(): void {
        this.dialogRef.close();
    }

    checkAppointmentType(): void {
        if(this.data.appointmentType == 'Subscription') {
            this.data.paymentOccurrence = 'Month';
        }
    }
}
