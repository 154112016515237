import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { TokenStorageService } from './token_storage.service';
import { ServiceProviderService } from './service-provider.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StripeCompleteGuardService implements CanActivate {

  constructor(private tokenStorageService: TokenStorageService, private serviceProviderService: ServiceProviderService) { }

  canActivate() {
    const user = this.tokenStorageService.getUser();

    return this.serviceProviderService.get([user.id], new Map()).pipe(map(u => {
      if (u.stripeExpressAccountComplete) {
        return true;
      } else {
        return false;
      }
    }));
  }
}
