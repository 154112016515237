import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-confirm-service-plan-edit-dialog',
  templateUrl: './confirm-service-plan-edit-dialog.component.html',
  styleUrls: ['./confirm-service-plan-edit-dialog.component.scss']
})
export class ConfirmServicePlanEditDialogComponent implements OnInit {

  agreeToTerms: FormControl = new FormControl(false);

  constructor() { }

  ngOnInit() {
  }

}
