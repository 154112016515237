import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ServicePlan } from 'src/app/models/service_plan.model';
import { ServiceProvider } from 'src/app/models/service_provider.model';
import { ServiceProviderService } from 'src/app/services/service-provider.service';
import { ServicePlanService } from 'src/app/services/service_plan.service';
import { TokenStorageService } from 'src/app/services/token_storage.service';
import { UpdatePhotoDialogComponent } from './update-photo-dialog/update-photo-dialog/update-photo-dialog.component';

@Component({
	selector: 'app-profile-page',
	templateUrl: './profile-page.component.html',
	styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent implements OnInit {
	loading = false;
	servicePlans: ServicePlan[] = [];
	profileImage!: any;
	serviceProvider!: ServiceProvider

	constructor(
		private servicePlanService: ServicePlanService,
		private userService: TokenStorageService,
		private serviceProviderService: ServiceProviderService,
		public dialog: MatDialog,
		private router: Router,
	) { }

	ngOnInit() {
		this.getServicePlans();
		this.getProfileImage();
		this.getServiceProvider();
	}

	getServicePlans(): void {
		this.loading = true;
		const queryParams = new Map([
			["serviceProviderId", this.userService.getUser().id],
			["isActive", true]
		]);
		this.servicePlanService.getAll([], queryParams).subscribe((result) => {
			this.servicePlans = result;
			this.loading = false;
		}, () => this.loading = false)
	}

	getServiceProvider(): void {
		this.loading = true;
		const queryParams = new Map();
		this.serviceProviderService.get([this.userService.getUser().id], queryParams).subscribe((serviceProvider) => {
			this.serviceProvider = serviceProvider;
			this.loading = false;
		}, () => this.loading = false);
	}

	getProfileImage(): void {
		const queryParams = new Map();
		this.serviceProviderService.get([this.userService.getUser().id, 'ProfileImage'], queryParams).subscribe((result) => {
			this.profileImage = result.data.imageData
		});
	}

	openUpdatePhotoDialog(): void {
		const dialogRef = this.dialog.open(UpdatePhotoDialogComponent);

		dialogRef.afterClosed().subscribe((updatedPhoto: boolean) => {
			if (updatedPhoto) {
				this.getProfileImage();
			}
		});
	}

	navigateToProfileManagement(): void {
		this.router.navigate(['profile-management']);
	}

	navigateToServiceManagement(): void {
		this.router.navigate(['service-management']);
	}
}
