<ng-container *ngIf="userId != null">
	<div class="flex-column align-center width-100">
		<h1 style="padding-bottom: 0; margin-bottom: 0;">
			Thank You for Setting Up Your Stripe Connect Account!
		</h1>

		<mat-divider class="width-75"></mat-divider>

		<p class="width-50 padding-tb-10">
			We appreciate your time in completing the setup of your Stripe Connect account. Your account is now integrated with
			Upper's health system infrastructure, allowing for seamless and secure payment processing. This step ensures that you
			can receive your payments faster and more securely.
		</p>

		<p>We are so glad to have you as a Provider in our health system.</p>

		<button mat-raised-button color="primary" (click)="completeExpressSetup()">
			Finish Setup
		</button>
	</div>
</ng-container>
