<ng-container *ngIf="expressSetupUrl != null">
	<div class="flex-column align-center width-100">
		<h1 style="padding-bottom: 0; margin-bottom: 0;">
			We've Updated Our Software for <strong>Faster Payments</strong> - It's time to <strong>create your Stripe Connect Account!</strong>
		</h1>

		<mat-divider class="width-75"></mat-divider>

		<p class="width-50 padding-tb-10">
			To provide you with the best payment experience and ensure the security of all transactions, we use Stripe for payment
			processing. Our latest tool called <strong>Stripe Connect</strong> allows us to seamlessly handle payments and payouts, ensuring that you
			receive your earnings <strong>promptly and more securely</strong>. By setting up this <strong>required Stripe Connect account,</strong> you are directly
			integrating with Stripe's robust financial infrastructure, which helps maintain compliance with industry standards and
			regulations. As of April 2024, Upper, and each of its Providers, are legally required to operate in compliance with
			these new marketplace regulations. Thank you for your continued partnership, and we look forward to sharing this
			advanced payment experience.
		</p>

		<p style="color: red;">This must be completed before you can begin, or continue, earning from Upper.</p>

		<mat-checkbox #checkbox>I Understand</mat-checkbox>
		
		<button mat-raised-button 
			color="primary" 
			[disabled]="!checkbox.checked" 
			(click)="navigateToExpressSetup()">
			Setup Stripe
		</button>
	</div>
</ng-container>