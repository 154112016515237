import { Component, OnInit } from '@angular/core';
import { ServiceProviderService } from 'src/app/services/service-provider.service';
import { TokenStorageService } from 'src/app/services/token_storage.service';

@Component({
  selector: 'app-stripe-setup',
  templateUrl: './stripe-setup.component.html',
  styleUrls: ['./stripe-setup.component.scss']
})
export class StripeSetupComponent implements OnInit {
  expressSetupUrl: string | null = null;

  constructor(private tokenStorageService: TokenStorageService, private serviceProviderService: ServiceProviderService) { }

  ngOnInit(): void {
    const user = this.tokenStorageService.getUser();

    this.serviceProviderService.get([user.id], new Map()).subscribe(provider => {
      this.expressSetupUrl = provider.stripeExpressSetupUrl
    });
  }

  navigateToExpressSetup(): void {
    window.location.href = <string>this.expressSetupUrl;
  }
}
