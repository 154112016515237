<div class="provider-form-border content">
    <h1 class="data mt-1">Update Personal Info</h1>
    <h3 class="success data" *ngIf="updateSuccess">*Update Success</h3>

    <form class="mr-5 data" [formGroup]="providerFormGroup" autocomplete="off">
        <div class="flex-row">
            <mat-form-field class="ml-2" appearance="outline">
                <mat-label>First Name</mat-label>
                <input matInput type="text" formControlName="firstName" required>
                <mat-error *ngIf="providerFormGroup.controls.firstName.hasError('required')">First Name is required.</mat-error>
            </mat-form-field>

            <mat-form-field class="ml-2" appearance="outline">
                <mat-label>Last Name</mat-label>
                <input matInput type="text" formControlName="lastName" required>
                <mat-error *ngIf="providerFormGroup.controls.lastName.hasError('required')">Last Name is required.</mat-error>
            </mat-form-field>

            <mat-form-field class="ml-2" appearance="outline">
                <mat-label>Primary Phone Number</mat-label>
                <mat-hint>Ex.123-123-1234</mat-hint>
                <input matInput type="text" formControlName="primaryPhoneNumber" minlength="12" maxlength="12" required>
                <mat-error *ngIf="providerFormGroup.controls.primaryPhoneNumber.hasError('required')">Primary Number is required.</mat-error>
                <mat-error *ngIf="providerFormGroup.controls.primaryPhoneNumber.hasError('pattern')">Invalid Format</mat-error>
                <mat-error *ngIf="providerFormGroup.controls.primaryPhoneNumber.hasError('maxlength')">Invalid Format</mat-error>
                <mat-error *ngIf="providerFormGroup.controls.primaryPhoneNumber.hasError('minlength')">Invalid Format</mat-error>
            </mat-form-field>

            <mat-form-field class="ml-2" appearance="outline">
                <mat-label>Secondary Phone Number</mat-label>
                <mat-hint>Ex.123-123-1234</mat-hint>
                <input matInput type="text" formControlName="secondaryPhoneNumber" maxlength="12">
                <mat-error *ngIf="providerFormGroup.controls.secondaryPhoneNumber.hasError('pattern')">Invalid Format</mat-error>
            </mat-form-field>
        </div>

        <div class="flex-row">
            <mat-form-field class="ml-2" appearance="outline">
                <mat-label>Address</mat-label>
                <input matInput type="text" formControlName="address" required>
                <mat-error *ngIf="providerFormGroup.controls.address.hasError('required')">Primary Number is required.</mat-error>
            </mat-form-field>

            <mat-form-field class="ml-2" appearance="outline">
                <mat-label>City</mat-label>
                <input matInput type="text" formControlName="city" required>
            </mat-form-field>

            <mat-form-field class="ml-2" appearance="outline">
                <mat-label>State</mat-label>
                <mat-select (change)="selectState($event)" formControlName="state">
                    <mat-option *ngFor="let state of states" [value]="state">{{state}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="ml-2" appearance="outline">
                <mat-label>Zip-Code</mat-label>
                <input matInput type="text" formControlName="zipCode" minlength="5" maxlength="5" required>
                <mat-error *ngIf="providerFormGroup.controls.zipCode.hasError('minlength')">Must be 5 characters</mat-error>
                <mat-error *ngIf="providerFormGroup.controls.zipCode.hasError('required')">Primary Number is required.</mat-error>
            </mat-form-field>
        </div>

        <div class="flex-row">
            <mat-form-field class="ml-2" appearance="outline">
                <mat-label>Business Name</mat-label>
                <input matInput type="text" formControlName="businessName" minlength="1" maxlength="50" required>
                <mat-error *ngIf="providerFormGroup.controls.businessName.hasError('minlength')">Must assign business name</mat-error>
                <mat-error *ngIf="providerFormGroup.controls.businessName.hasError('required')">Business Name is required.</mat-error>
            </mat-form-field>

            <mat-form-field class="ml-2 bio-text-area" appearance="outline">
                <mat-label>Bio</mat-label>
                <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="5" cdkAutosizeMaxRows="11" formControlName="profileBio" placeholder="Update Bio" maxlength="950"></textarea>
                <mat-error *ngIf="providerFormGroup.controls.profileBio.hasError('maxlength')">Max Bio length limit</mat-error>
            </mat-form-field>

        </div>


        <button type="submit" (click)="saveProviderUpdates()" [disabled]="!providerFormGroup.valid" mat-raised-button color="primary" class="ml-2 mt-2 mb-5">
            Save
        </button>

    </form>
</div>